import React, { useState, useTransition } from "react";
import { Bar } from "react-chartjs-2";
import { FaArrowLeft } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";

const SoftSkillDep = () => {
  const { t } = useTransition;
  const [drop, setDrop] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const data = [
    {
      name: "Name Surname",
      mpi: 23,
      ci: 23,
      riskTaking: 12,
      detailedAnalysis: 22,
    },
    {
      name: "Enkhjin Otgonjargal",
      mpi: 45,
      ci: 45,
      riskTaking: 14,
      detailedAnalysis: 23,
    },
    {
      name: "Name Surname",
      mpi: 45,
      ci: 45,
      riskTaking: 24,
      detailedAnalysis: 34,
    },
    {
      name: "Enkhjin Otgonjargal",
      mpi: 24,
      ci: 37,
      riskTaking: 27,
      detailedAnalysis: 36,
    },
    {
      name: "Name Surname",
      mpi: 39,
      ci: 56,
      riskTaking: 5,
      detailedAnalysis: 54,
    },
  ];

  const labels = [
    "Бусдад итгэх хандлага",
    "Нарийвчилан дүгнэх",
    "Эрдсэл хүлээх чадвар",
    "Сониуч зан",
    "Багаар ажиллах чадвар",
    "Хэв таних",
    "Суралцах хурд",
    "Өөртөө итгэлтэй, шийдэмгий байдал",
    "Төлөвлөх чадвар",
    "Шийдвэр гаргах чадвар",
    "Нухацтай сэтгэг",
    "Ажлын санах ой",
    "Зорилго төлөвлөлт",
    "Анхаарал",
    "Боловсруулах хурд",
    "Тоон сэтгэг",
    "Ерөнхийлөн дүгнэх",
    "Туштай байдал",
    "Дасан зохицох чадвар",
  ];

  const emp = [
    {
      name: "Enkhjin Otgonjargal",
      mpi: 12,
      ci: 12,
    },
    {
      name: "Name Surname",
      mpi: 0,
      ci: 0,
    },
    {
      name: "Name Surname",
      mpi: 0,
      ci: 0,
    },
    {
      name: "Name Surname",
      mpi: 0,
      ci: 0,
    },
    {
      name: "Name Surname",
      mpi: 0,
      ci: 0,
    },
  ];

  const scores = [
    24, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5,
  ];

  const colors = scores.map((score, index) => {
    if (index < 5) {
      return "#34D399";
    } else if (index >= scores.length - 2) {
      return "#F87171";
    } else {
      return "#FBBF24";
    }
  });

  const datas = {
    labels,
    datasets: [
      {
        label: "Score",
        data: scores,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const handleLabelSelect = (label) => {
    if (!selectedLabels.includes(label)) {
      setSelectedLabels([...selectedLabels, label]);
    }
  };

  return (
    <div className="w-10/12 pt-11 m-auto">
      <button className="text-sm flex items-center gap-2">
        <FaArrowLeft />
        Буцах
      </button>
      <div className="pt-10">
        <p className="text-[24px] text-[#222]">Маркетингийн хэлтэс</p>

        <div className="flex items-center gap-10 pt-8">
          <div className="w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3">
            <div>
              <p className="text-sm text-[#666874] mb-2">Дундаж MPI оноо</p>
              <p className="text-[24px] font-semibold text-[#324D72]">52</p>
            </div>
          </div>
          <div className="w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3">
            <div>
              <p className="text-sm text-[#666874] mb-2">Дундаж CI оноо</p>
              <p className="text-[24px] font-semibold text-[#324D72]">52</p>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-10 items-start">
          <div className="w-[80%] max-w-4xl mx-auto mt-10">
            <Bar data={datas} options={options} />
          </div>
          <div className="w-[20%] mt-12">
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#34D399]" />
              <p>High score</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#FBBF24]" />
              <p>Mid score</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#F87171]" />
              <p>Low score</p>
            </div>
          </div>
        </div>
        <div className="mt-11">
          <p className="text-[#1E293B] font-semibold pb-10">
            Ажилтан тус бүрээр
          </p>
          <div className="w-full overflow-x-auto pb-10">
            <table className="w-full min-w-max">
              <thead>
                <tr>
                  <th className="px-3 py-3 font-semibold text-[#1E293B] text-sm">
                    Овог Нэр
                  </th>
                  <th className="px-3 py-3 font-semibold text-[#1E293B] text-sm">
                    MPI оноо
                  </th>
                  <th className="px-3 py-3 font-semibold text-[#1E293B] text-sm">
                    CI оноо
                  </th>
                  {selectedLabels.map((label, index) => (
                    <th
                      key={index}
                      className="px-3 py-3 font-semibold text-[#1E293B] text-sm"
                    >
                      {label}
                    </th>
                  ))}
                  <th className="px-3 py-3 relative">
                    <button
                      className="font-semibold text-[#1E293B] text-sm flex items-center gap-2"
                      onClick={() => {
                        setDrop(!drop);
                      }}
                    >
                      <p>Ур чадвар сонгоно уу.</p>
                      <IoIosArrowDown />
                    </button>
                    {drop && (
                      <div className="max-h-[200px] absolute top-10 overflow-y-auto bg-white shadow-custom p-2 rounded-lg z-10">
                        {labels.map((label, index) => (
                          <div
                            key={index}
                            className={`px-4 py-2 hover:bg-[#E9EDF5] border-b border-[#EFEFEF] hover:rounded-lg text-start cursor-pointer ${
                              selectedLabels.includes(label)
                                ? "bg-[#E9EDF5]"
                                : ""
                            }`}
                            onClick={() => {
                              handleLabelSelect(label);
                              setDrop(false);
                            }}
                          >
                            {label}
                          </div>
                        ))}
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {emp.map((items, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "bg-[#F5F5F6]" : ""}`}
                  >
                    <td className="px-3 py-3">{items.name}</td>
                    <td className="px-3 py-3">{items.mpi}</td>
                    <td className="px-3 py-3">{items.ci}</td>
                    {selectedLabels.map((label, labelIndex) => (
                      <td key={labelIndex} className="px-3 py-3">
                        Value for {label}
                      </td>
                    ))}
                    <td className="px-3 py-3">-</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftSkillDep;
