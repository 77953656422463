import { Step, StepLabel, Stepper } from "@mui/material";
import { STEPS } from '../../helper/constants';

export const GameStepper = ({ activeStep, language }) => {
  const steps = STEPS[language] || STEPS.mn;
  
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}; 