import api from "../helper/request";

const login = (obj) =>
  api.post("/user/login", obj, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const createPlayer = (obj) => api.post("/player", obj);

const createCompanyUser = (obj) => api.post("/user/company", obj);

const getCompanyUsers = () => api.post("/userCompany/users");

const getUserInfo = () => api.get("/user");

const changeUserPassword = (obj) => api.post("/user/password", obj);

const createAccount = (obj) => api.post("/user/account", obj);

const getAccountInfo = () => api.post("/user/account-info");

const changeAccountInfo = (obj) =>
  api.post("/user/account-info/change", obj, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const changeCompanyPhoto = (obj) => api.post("/user/companyPhotoChange", obj);

const createAdminCompany = (obj) => api.post("/user/account/admin", obj);

const removeCompanyUser = (obj) => api.post("/user/account/remove", obj);

const emailConfirmation = (obj) => api.get(`/company/email/confirmation/${obj}`)

const loginByAzureOauth = obj => api.post(`/user/azure/oauth?email=${obj}`);

export {
  login,
  createPlayer,
  createCompanyUser,
  getCompanyUsers,
  getUserInfo,
  changeUserPassword,
  createAccount,
  getAccountInfo,
  changeAccountInfo,
  createAdminCompany,
  changeCompanyPhoto,
  removeCompanyUser,
  emailConfirmation,
  loginByAzureOauth
};
