import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SurveySummary from "./SurveySummary";
import SurveyTable from "./SurveyTable";
import EditSurvey from "./EditSurvey";
import { getSurveySummery } from "../../../services/survey.service";
import Loading from "../../../components/uncommon/Loading";
import { useTranslation } from "react-i18next";

const SurverNewReport = () => {
  const [activePage, setActivePage] = useState("summary");
  const { id } = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const handleChangePage = (page) => {
    setActivePage(page);
    navigate(`${page}`);
  };

  useEffect(() => {
    getSurveySummery(id)
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const renderActivePage = () => {
    switch (activePage) {
      case "summary":
        return <SurveySummary datas={data} surveyId={id} />;
      case "table":
        return <SurveyTable />;
      case "edit":
        return <EditSurvey />;
      default:
        return null;
    }
  };

  if (data === null) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const surveyFinishedPercentage =
    (Number(data.surveyFinished) / Number(data.surveySent)) * 100;
  return (
    <div className="bg-[#F3F4F8] min-h-screen">
      <div className="pt-11 mx-6 m-auto">
        <div
          className={`bg-[#fff] py-4 ${
            activePage === "summary" ? "rounded-xl" : " rounded-t-xl"
          }`}
        >
          <div className="px-8">
            <div className="border-b  flex items-center justify-center py-2 gap-8">
              {/* <div
                onClick={() => {
                  handleChangePage("edit");
                }}
                className={`py-2 cursor-pointer ${
                  activePage === "edit" ? "border-b-2 border-[#000]" : ""
                }`}
              >
                <p
                  className={`text-sm text-[#222] ${
                    activePage === "edit" ? "" : "opacity-70"
                  }`}
                >
                  Survey
                </p>
              </div> */}
              <div
                onClick={() => {
                  handleChangePage("summary");
                }}
                className={`py-2 cursor-pointer ${
                  activePage === "summary" ? "border-b-2 border-[#000]" : ""
                }`}
              >
                <p
                  className={`text-sm text-[#222] ${
                    activePage === "summary" ? "" : "opacity-70"
                  }`}
                >
                  Summary
                </p>
              </div>
              {/* <div
                onClick={() => {
                  handleChangePage("table");
                }}
                className={`py-2 cursor-pointer ${
                  activePage === "table" ? "border-b-2 border-[#000]" : ""
                }`}
              >
                <p
                  className={`text-sm text-[#222] ${
                    activePage === "table" ? "" : "opacity-70"
                  }`}
                >
                  response [..]
                </p>
              </div> */}
            </div>
          </div>
          {activePage === "summary" && (
            <div className="flex items-center justify-center mt-2 gap-8">
              <div>
                <p className="text-[#222] text-sm opacity-70">
                  {t("Survey.questionSent")}
                </p>
                <p className="text-[#222] text-[36px]">{data.surveySent}</p>
              </div>
              {/* <div>
                <p className="text-[#222] text-sm opacity-70">Эхлүүлсэн</p>
                <p className="text-[#222] text-[36px]">...</p>
              </div> */}
              <div>
                <p className="text-[#222] text-sm opacity-70">
                  {t("Survey.completed")}
                </p>
                <p className="text-[#222] text-[36px]">{data.surveyFinished}</p>
              </div>
              <div>
                <p className="text-[#222] text-sm opacity-70">
                  {t("Survey.performanceProg")}
                </p>
                <p className="text-[#222] text-[36px] ">
                  {data.surveyFinished === 0
                    ? "0%"
                    : `${surveyFinishedPercentage.toFixed(0)}%`}
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          <div>{renderActivePage()}</div>
        </div>
      </div>
    </div>
  );
};

export default SurverNewReport;
