/* eslint-disable */
import { isArray } from 'lodash';
import moment from 'moment';
import { MGL_UPPER_CHARACTERS } from './constants';

export const isPhoneNumber = (value) => {
  if (!value) return Promise.reject('Утасны дугаар хоосон байна.');
  value = `${value}`;
  if (value.length !== 8)
    return Promise.reject('Утасны дугаар 8 оронтой байна.');
  for (let i = 0; i < value.length; i++) {
    const element = value.charAt(i);
    if (!('0' <= element && element <= '9'))
      return Promise.reject('Утасны дугаар зөвхөн тоо байна.');
  }
  return Promise.resolve();
};

export const isRegisterNumber = (value) => {
  if (!value) return Promise.reject('Регистрийн дугаар хоосон байна');
  if (value.length === 0)
    return Promise.reject('Регистрийн дугаар хоосон байна');
  const res = MGL_UPPER_CHARACTERS.find(
    (e) => e === value.charAt(0).toUpperCase(),
  );
  if (!res) {
    return Promise.reject(
      'Регистрийн дугаарын эхний үсэг монгол үсэг биш байна. ',
    );
  }
  if (value.length > 1) {
    const d = MGL_UPPER_CHARACTERS.find(
      (e) => e === value.charAt(1).toUpperCase(),
    );
    if (!d) {
      return Promise.reject(
        'Регистрийн дугаарын хоёр дахь үсэг монгол үсэг биш байна. ',
      );
    }
  }
  const len = value.length > 10 ? 10 : value.length;
  for (let index = 2; index < len; index += 1) {
    const element = value[index];
    if (!(element >= '0' && element <= '9')) {
      return Promise.reject(
        `Регистрийн дугаарын ${index + 1} үсэг тоо биш байна`,
      );
    }
  }
  if (value.length !== 10)
    return Promise.reject('Харилцагчийн регистр дугаар 10 оронтой байна');
  return Promise.resolve();
};

export const checkNumberLetter = (value) => {
  if (value) {
    const lastChar = value.slice(-1);
    if (
      (lastChar.charCodeAt(0) >= 48 && lastChar.charCodeAt(0) <= 57) ||
      (lastChar.charCodeAt(0) >= 65 && lastChar.charCodeAt(0) < 91) ||
      (lastChar.charCodeAt(0) >= 97 && lastChar.charCodeAt(0) < 123)
    ) {
      return value;
    }
    return value.substring(0, value.length - 1);
  }
};

export const isBlankWithFieldName = async (val, FName) => {
  if (!val) return Promise.reject(`${FName} дугаар хоосон байна. `);
  return Promise.resolve();
};

export const isCompany = (value) => {
  const len = value.length > 7 ? 7 : value.length;
  for (let index = 0; index < len; index += 1) {
    const element = value[index];
    if (!(element >= '0' && element <= '9')) {
      return Promise.reject(
        `Компани регистрийн дугаарын ${index + 1} орон тоо биш байна`,
      );
    }
  }
  if (value.length !== 7)
    return Promise.reject('Компани регистрийн дугаар 7 оронтой байна');
  return Promise.resolve();
};

export const searchLike = (obj) => {
  if (obj == null) return '';
  let str = '';
  Object.keys(obj).forEach((e) => {
    if (Array.isArray(obj[e])) str += obj[e].map((w) => `${e}~${w},`);
  });
  return str;
};

export const isTtd = (value) => {
  if (value.length > 0 && value.charAt(0) >= '0' && value.charAt(0) <= '9') {
    return isCompany(value);
  }
  return isRegisterNumber(value);
};

export const buildAnd = (arr) => {
  if (arr?.length === 0) return '';
  const w = arr.filter((e) => e !== undefined && e !== '' && e !== null);
  const str = w?.reduce((a, b) => `${a} AND ${b}`);
  return `( ${str} )`;
};

export const buildOr = (arr) => {
  if (arr?.length === 0) return '';
  const str = arr?.reduce((a, b) => `${a} OR ${b}`);
  return `( ${str} )`;
};

export const buildIn = (column, arr) =>
  buildOr(arr?.map((e) => `${column}:${e}`));

export const datetimeBetween = (key, date) => {
  if (!date) return '';
  const [l, r] = date;
  const lformat = moment(l).format('YYYYMMDDHHmmss');
  const rformat = moment(r).format('YYYYMMDDHHmmss');
  return `( ( ${key}<${rformat} OR ${key}:${rformat} ) AND ( ${key}>${lformat} OR ${key}:${lformat} ) )`;
};

export const dateBetween = (key, date) => {
  if (!date) return '';
  const [l, r] = date;
  const lformat = moment(l).format('YYYYMMDD');
  const rformat = moment(r).format('YYYYMMDD');
  return `( ( ${key}<${rformat} OR ${key}:${rformat} ) AND ( ${key}>${lformat} OR ${key}:${lformat} ) )`;
};

export const searchFilter = (obj) => {
  let str = '';
  Object.keys(obj).forEach((e) => {
    if (obj[e] && obj[e] !== '') str += `${e}:${obj[e]},`;
  });
  return str;
};

export const compareDateTime = (a, b) => {
  return new Date(a) > new Date(b);
};

export const deleteKeysForUndefiedValue = (obj) => {
  let resp = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null) return;
    resp = {
      ...resp,
      [key]: obj[key],
    };
  });
  return resp;
};

export const sortChanger = (val) => {
  if (val === 'ascend') {
    return 'asc';
  } else if (val === 'descend') {
    return 'desc';
  }
};

export const findSortInfo = (sortArr, key) => {
  if (isArray(sortArr)) {
  } else {
    return sortArr.order;
  }
};

export const daysConverter = (days) => {
  if (days) {
    let year = 0;
    let months = 0;
    let dayOf = 0;

    year = parseInt(days / 365);
    months = parseInt((days - year * 365) / 30);
    dayOf = parseInt((days - year * 365) % 30);

    return {
      year,
      month: months,
      day: dayOf,
    };
  } else {
    return {
      year: 0,
      month: 0,
      day: 0,
    };
  }
};


export const generateCodeVerifier = () => {
  const array = new Uint32Array(32);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ('0' + dec.toString(16)).slice(-2)).join('');
};

export const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await crypto.subtle.digest("SHA-256", data);
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};