import api from "../helper/request";

const createScore = (obj) => api.post("/gameScore", obj);

const createApuScore = (obj) => api.post("/apu/score", obj);

const getPlayerReport = (obj) => api.get(`/gameScore/${obj}`);

const createMissingScores = (obj) => api.post("/gameScore/missingScores", obj);

const createApuMissingScores = (obj) => api.post("/apu/score/missingScores", obj);

const createBurnoutScore = (obj) => api.post("/burnout/score", obj);

const startCreditUser = (obj) => api.get(`/rd/start/${obj}`);

const createCreditFullScore = (obj) => api.post(`/rd/score`, obj);

const createCreditScore = (obj) => api.post('/rd/score/single', obj);

const getEmployeeGameByLink = (obj) => api.post(`/assessment/find/employee`, obj);

export { createScore, getPlayerReport, createMissingScores, createBurnoutScore, startCreditUser, createCreditFullScore, createCreditScore, createApuScore, createApuMissingScores, getEmployeeGameByLink };

