const getRedirectUri = () => {
    return window.location.hostname.includes("oneplace")
        ? "https://app.oneplace.hr/oauth2/login"
        : "https://app.metacog.mn/oauth2/login";
};

export const azureConfig = {
    clientId: "a21aca06-7ff2-4807-9ccf-8b37272e70a9",
    tenantId: "916191a6-64d2-4648-be45-e14d62a20fc2",
    redirectUri: getRedirectUri(),
    scopes: ["openid", "profile", "email", "User.Read"],
    authority: `https://login.microsoftonline.com/916191a6-64d2-4648-be45-e14d62a20fc2`,
};
