import React, { useEffect, useState } from "react";
import { IoMdKey, IoMdClose } from "react-icons/io";
import { FaCheck, FaMagnifyingGlass, FaPlus } from "react-icons/fa6";
import { GoPlusCircle } from "react-icons/go";
import { TbArrowsExchange2 } from "react-icons/tb";

import { MdDeleteOutline } from "react-icons/md";
import "../../../css/additional-styles/costum-checkbox.scss";

const Dropdown = ({ options, selectedOption, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-[160px]">
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="w-full text-left text-xs py-1 px-3 text-[#666874] border border-[#666874] rounded-xl flex items-center gap-2"
      >
        {selectedOption === "checkbox" ? (
          <div className="flex items-center justify-center border border-[#324D72] rounded-full w-[12px] h-[12px] me-1">
            <div className="w-[8px] h-[8px] bg-[#324d72] rounded-full"></div>
          </div>
        ) : selectedOption === "multiple" ? (
          <div className="flex items-center justify-center bg-[#324D72] rounded-md w-[12px] h-[12px] me-1">
            <FaCheck className="text-sm text-[#fff]" />
          </div>
        ) : (
          <div className="flex items-center justify-center bg-[#324D72] rounded-md w-[12px] h-[12px] me-1">
            <TbArrowsExchange2 className="text-sm text-[#fff]" />
          </div>
        )}
        {selectedOption
          ? options.find((option) => option.value === selectedOption)?.label
          : "Ганц сонголттой"}
      </button>
      {isOpen && (
        <ul className="absolute z-10 bg-white border border-gray-300 rounded-md mt-1 w-full shadow-md">
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleSelect(option.value)}
              className="flex items-center gap-2 px-3 py-2 cursor-pointer hover:bg-[#E9EDF5] text-xs text-[#3D608C] hover:text-[#324d72]"
            >
              <div className="flex items-center">
                {option.value === "checkbox" ? (
                  <div className="flex items-center justify-center border border-[#324D72] rounded-full w-[12px] h-[12px] me-1">
                    <div className="w-[8px] h-[8px] bg-[#324d72] rounded-full"></div>
                  </div>
                ) : option.value === "multiple" ? (
                  <div className="flex items-center justify-center bg-[#324D72] rounded-md w-[12px] h-[12px] me-1">
                    <FaCheck className="text-sm text-[#fff]" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center bg-[#324D72] rounded-md w-[12px] h-[12px] me-1">
                    <TbArrowsExchange2 className="text-sm text-[#fff]" />
                  </div>
                )}
                <span>{option.label}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const TestQuestions = ({ test, setTest, setPoints, setRequiredPoints }) => {
  const [topic, setTopic] = useState("");
  const [questions, setQuestions] = useState([]);

  const [adding, setAdding] = useState(false);
  const [checking, setChecking] = useState(null);

  useEffect(() => {
    const totalPoints = calculateTotalPoints();
    setPoints(totalPoints);
    setRequiredPoints(Math.round(totalPoints / 2));
  }, [test.topic]);

  const handleAddTopic = () => {
    if (topic.trim()) {
      const newTopic = {
        name: topic,
        point: 0,
        type: "checkbox",
        questions: [],
      };

      setTest((prevTest) => ({
        ...prevTest,
        topic: [...prevTest.topic, newTopic],
      }));

      setTopic("");
      setAdding(false);
    }
  };

  const handleTypeChange = (topicIndex, value) => {
    const updatedTopic = [...test.topic];
    updatedTopic[topicIndex].type = value;
    setTest((prev) => ({ ...prev, topic: updatedTopic }));
  };

  const handleAddQuestion = (topicIndex) => {
    if (questions[topicIndex]?.trim()) {
      const newQuestion = { text: questions[topicIndex], isAnswer: false };
      const updatedTest = { ...test };
      updatedTest.topic[topicIndex].questions.push(newQuestion);
      setTest(updatedTest);

      const updatedQuestions = [...questions];
      updatedQuestions[topicIndex] = "";
      setQuestions(updatedQuestions);
    }
  };

  const toggleQuestionCheck = (topicIndex, questionIndex) => {
    setTest((prevTest) => {
      const updatedTestTopics = [...prevTest.topic];

      const topicType = updatedTestTopics[topicIndex].type;

      if (topicType === "multiple") {
        updatedTestTopics[topicIndex].questions = updatedTestTopics[
          topicIndex
        ].questions.map((question, index) => ({
          ...question,
          isAnswer:
            index === questionIndex ? !question.isAnswer : question.isAnswer,
        }));
      } else if (topicType === "checkbox") {
        updatedTestTopics[topicIndex].questions = updatedTestTopics[
          topicIndex
        ].questions.map((question, index) => ({
          ...question,
          isAnswer: index === questionIndex,
        }));
      }

      return { ...prevTest, topic: updatedTestTopics };
    });
  };

  const handleDeleteQuestion = (topicIndex, questionIndex) => {
    setTest((prevTest) => {
      const updatedTestTopics = prevTest.topic.map((topic, idx) => {
        if (idx === topicIndex) {
          const updatedQuestions = topic.questions.filter(
            (_, qIdx) => qIdx !== questionIndex
          );
          return { ...topic, questions: updatedQuestions };
        }
        return topic;
      });

      return { ...prevTest, topic: updatedTestTopics };
    });
  };

  const handleDeleteTopic = (topicIndex) => {
    setTest((prevTest) => ({
      ...prevTest,
      topic: prevTest.topic.filter((_, index) => index !== topicIndex),
    }));
  };

  const handlePointChange = (topicIndex, newPoint) => {
    setTest((prevTest) => ({
      ...prevTest,
      topic: prevTest.topic.map((item, index) =>
        index === topicIndex ? { ...item, point: newPoint } : item
      ),
    }));
  };

  const calculateTotalPoints = () => {
    return test.topic.reduce((sum, topic) => sum + topic.point, 0);
  };

  return (
    <div>
      <div className="m-auto w-10/12  flex justify-center items-center">
        <div>
          <div className="w-[514px] bg-[#fff] rounded-xl p-4 mb-4">
            <input
              className="text-[#1E293B] text-[20px] w-full border-none rounded-lg"
              onChange={(e) => setTest({ ...test, name: e.target.value })}
              placeholder="Нэр өгнө үү."
              type="text"
            />
            <hr className="bg-[#E5E6E8] h-[2px] my-2" />
            <input
              className="text-xs text-[#575763] w-full border-none rounded-lg"
              onChange={(e) =>
                setTest({ ...test, description: e.target.value })
              }
              placeholder="Тайлбар бичнэ үү"
              type="text"
            />
          </div>

          {test.topic.map((item, topicIndex) => (
            <div
              key={topicIndex}
              className="w-[514px] bg-[#fff] rounded-xl p-4 mb-4 relative overflow-hidden"
            >
              {checking === topicIndex && (
                <div className="h-[7px] w-[514px] rounded-t-xl bg-[#324d72] absolute top-0 left-0" />
              )}
              <div className="flex items-center gap-4 justify-between">
                <textarea
                  value={item.name}
                  onChange={(e) => {
                    const updatedTopic = [...test.topic];
                    updatedTopic[topicIndex].name = e.target.value;
                    setTest((prev) => ({ ...prev, topic: updatedTopic }));
                  }}
                  className="w-[75%] h-[40px] p-2 text-sm text-[#1E293B] border border-[#E5E7EB] rounded-md"
                />
                <Dropdown
                  options={[
                    { label: "Ганц сонголттой", value: "checkbox" },
                    { label: "Олон сонголттой", value: "multiple" },
                    { label: "Харгалзуулах", value: "consider" },
                  ]}
                  selectedOption={item.type}
                  onChange={(value) => handleTypeChange(topicIndex, value)}
                />
              </div>
              <hr className="h-[2px] bg-[#E5E6E8] my-2" />
              {checking === topicIndex && (
                <div className="flex justify-end items-center gap-2">
                  <input
                    className="border-none rounded-sm p-0 w-[40px] text-xs text-[#424348]"
                    value={item.point}
                    onChange={(e) =>
                      handlePointChange(topicIndex, Number(e.target.value))
                    }
                    type="number"
                  />
                  <p className="text-[#424348] text-xs">оноо</p>
                </div>
              )}
              {item.questions.map((question, questionIndex) => (
                <div key={questionIndex}>
                  {item.type === "consider" ? (
                    <div>
                      {checking === topicIndex ? (
                        <div></div>
                      ) : (
                        <div className="flex items-center justify-between"></div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {checking === topicIndex ? (
                        <div>
                          <div
                            onClick={() => {
                              toggleQuestionCheck(topicIndex, questionIndex);
                            }}
                            className={`flex items-center  justify-between px-3 py-1.5 rounded-md mt-2 ${
                              question.isAnswer
                                ? "bg-[#00D97D] bg-opacity-20"
                                : ""
                            }`}
                          >
                            <div className="flex items-center gap-2 ">
                              {item.type === "checkbox" ? (
                                <div className="flex items-center">
                                  <label className="custom-checkbox flex items-center justify-center">
                                    <input
                                      type="checkbox"
                                      checked={question.isAnswer}
                                      defaultChecked={question.isAnswer}
                                    />
                                    <span
                                      className={`checkmark ${
                                        item.type === "checkbox"
                                          ? "rounded-full"
                                          : ""
                                      }`}
                                    ></span>
                                  </label>
                                </div>
                              ) : item.type === "multiple" ? (
                                <div className="flex items-center">
                                  <label className="custom-checkbox flex items-center justify-center">
                                    <input
                                      type="checkbox"
                                      checked={question.isAnswer}
                                      defaultChecked={question.isAnswer}
                                    />
                                    <span
                                      className={`checkmark ${
                                        item.type === "multiple"
                                          ? "rounded-10px"
                                          : ""
                                      }`}
                                    ></span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}

                              <p className="text-xs text-wrap text-[#1E293B]">
                                {question.text}
                              </p>
                            </div>
                            <div className="flex items-center gap-2">
                              {question.isAnswer && (
                                <FaCheck className="text-[#00D97D]" />
                              )}
                              <button
                                onClick={() =>
                                  handleDeleteQuestion(
                                    topicIndex,
                                    questionIndex
                                  )
                                }
                                className="text-[#575763] text-xl"
                              >
                                <IoMdClose />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={questionIndex}
                          className={`flex items-center  justify-between px-3 py-1.5 rounded-md mt-2 ${
                            question.isAnswer
                              ? "bg-[#00D97D] bg-opacity-20"
                              : ""
                          }`}
                        >
                          <p className="text-xs text-wrap text-[#1E293B]">
                            {question.text}
                          </p>
                          <div className="flex items-center gap-2">
                            {question.isAnswer && (
                              <FaCheck className="text-[#00D97D]" />
                            )}
                            <button
                              onClick={() =>
                                handleDeleteQuestion(topicIndex, questionIndex)
                              }
                              className="text-[#575763] text-xl"
                            >
                              <IoMdClose />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
              {item.type === "consider" ? (
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2 px-3 py-1.5 rounded-md w-[45%]">
                    <input
                      className="w-full text-xs ps-2 py-1 text-[#1E293B] rounded-lg border-none mt-2"
                      onChange={(e) => {
                        const updatedQuestions = [...questions];
                        updatedQuestions[topicIndex] = e.target.value;
                        setQuestions(updatedQuestions);
                      }}
                      placeholder="Хариулт нэмэх"
                      value={questions[topicIndex] || ""}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleAddQuestion(topicIndex);
                        }
                      }}
                      type="text"
                    />

                    <button onClick={() => handleAddQuestion(topicIndex)}>
                      <FaPlus />
                    </button>
                  </div>
                  <div className="flex items-center gap-2 px-3 py-1.5 rounded-md w-[45%]">
                    <input
                      className="w-full text-xs ps-2 py-1 text-[#1E293B] rounded-lg border-none mt-2"
                      onChange={(e) => {
                        const updatedQuestions = [...questions];
                        updatedQuestions[topicIndex] = e.target.value;
                        setQuestions(updatedQuestions);
                      }}
                      placeholder="Хариулт нэмэх"
                      value={questions[topicIndex] || ""}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleAddQuestion(topicIndex);
                        }
                      }}
                      type="text"
                    />

                    <button onClick={() => handleAddQuestion(topicIndex)}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-2 px-3 py-1.5 rounded-md">
                  <input
                    className="w-full text-xs ps-2 py-1 text-[#1E293B] rounded-lg border-none mt-2"
                    onChange={(e) => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[topicIndex] = e.target.value;
                      setQuestions(updatedQuestions);
                    }}
                    placeholder="Хариулт нэмэх"
                    value={questions[topicIndex] || ""}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddQuestion(topicIndex);
                      }
                    }}
                    type="text"
                  />

                  <button onClick={() => handleAddQuestion(topicIndex)}>
                    <FaPlus />
                  </button>
                </div>
              )}
              <div className="flex items-center justify-between mt-4 px-3 rounded-md">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => {
                      if (topicIndex === checking) {
                        setChecking(null);
                      } else {
                        setChecking(topicIndex);
                      }
                    }}
                    className="text-[#324D72] flex items-center gap-1 "
                  >
                    <span className="text-xl">
                      <IoMdKey />
                    </span>
                    <span className="text-[14px]">Зөв хариулт</span>
                  </button>
                  <p className="text-[#424348] text-[13px]">
                    ({item.point} оноо)
                  </p>
                </div>
                <div>
                  {topicIndex === checking && (
                    <button
                      onClick={() => {
                        setChecking(null);
                      }}
                      className="px-3 py-1 border border-[#CECFD3] rounded-lg text-[#324d72] text-sm"
                    >
                      Болсон
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteTopic(topicIndex)}
                    className="text-[#707070] text-xl"
                  >
                    <MdDeleteOutline />
                  </button>
                </div>
              </div>
            </div>
          ))}

          {adding ? (
            <div className="flex items-center justify-center gap-2">
              <div className="w-[514px] bg-[#fff] rounded-xl p-4 mb-4 flex items-center">
                <textarea
                  className="w-full text-sm text-[#1E293B] rounded-lg border-none"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddTopic();
                    }
                  }}
                  placeholder="Асуулт нэмэх"
                  type="text"
                />
                <button onClick={handleAddTopic} className=" ml-2">
                  <FaPlus />
                </button>
              </div>
            </div>
          ) : (
            <div className="w-[514px] flex justify-end">
              <button
                onClick={() => {
                  setAdding(true);
                }}
                className="text-[#324D72] text-sm flex items-center gap-2 py-1 px-3 rounded-lg bg-[#fff] font-semibold"
              >
                <GoPlusCircle />
                Асуулт нэмэх
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestQuestions;
