import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#30e83c" : "#30e880",
  },
}));

export const LoadingProgress = ({ progress, message }) => (
  <div className="fixed inset-0 bg-[#f6f7f8] z-20 flex items-center justify-center">
    <div className="text-center">
      <div className="text-lg">
        {message}
        <div className="font-bold">{progress.toFixed(2)}%</div>
      </div>
      <div className="w-[350px] mt-4">
        <BorderLinearProgress variant="determinate" value={progress} />
      </div>
    </div>
  </div>
); 