import React, { useEffect, useState } from "react";
import { LuEye } from "react-icons/lu";
import {
  IoMdArrowBack,
  IoMdArrowForward,
  IoIosArrowBack,
  IoIosClose,
  IoMdClose,
} from "react-icons/io";
import {
  getCompanyEmployee,
  getDepartments,
} from "../../../services/employee.service";
import Loading from "../../../components/uncommon/Loading";
import TestQuestions from "./TestQuestions";
import TestAnswers from "./TestAnswers";
import ReactQuill from "react-quill";
import { FaMagnifyingGlass } from "react-icons/fa6";

const TestCreate = () => {
  const [test, setTest] = useState({
    name: "",
    description: "",
    type: "",
    topic: [],
  });

  const [points, setPoints] = useState(0);
  const [requiredPoints, setRequiredPoints] = useState(0);
  const [warning, setWarning] = useState(false);

  const [table, setTable] = useState("questions");
  const [loading, setLoading] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [popUp, setPopUp] = useState(false);
  const [popUp2, setPopUp2] = useState(false);

  const [emailShow, setEmailShow] = useState(false);

  //
  const [searchQuery, setSearchQuery] = useState("");
  const [allChecked, setAllChecked] = useState(false);
  const [checkedEmployees, setCheckedEmployees] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  useEffect(() => {
    getCompanyEmployee()
      .then((data) => {
        setEmployees(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getDepartments()
      .then((data) => {
        setDepartments(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, []);

  const renderActivePage = () => {
    switch (table) {
      case "questions":
        return (
          <TestQuestions
            employees={employees}
            departments={departments}
            test={test}
            setTest={setTest}
            setPoints={setPoints}
            setRequiredPoints={setRequiredPoints}
          />
        );
      case "answers":
        return <TestAnswers />;
      default:
        return null;
    }
  };

  if (loading) {
    return <Loading />;
  }

  const getDepartmentName = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "Unknown Department";
  };

  const employeesByDepartment = employees?.reduce((dep, employee) => {
    const departmentId = employee.departmentId;
    const departmentName = getDepartmentName(departmentId);

    if (!dep[departmentName]) {
      dep[departmentName] = [];
    }

    dep[departmentName].push(employee);
    return dep;
  }, {});

  const handleCheckAll = () => {
    const updatedCheckedEmployees = {};
    const newAllChecked = !allChecked;

    employees.forEach((employee) => {
      updatedCheckedEmployees[employee.id] = newAllChecked;
    });

    Object.keys(employeesByDepartment).forEach((departmentName) => {
      updatedCheckedEmployees[departmentName] = newAllChecked;
    });

    setCheckedEmployees(updatedCheckedEmployees);
    setAllChecked(newAllChecked);

    if (newAllChecked) {
      setSelectedEmployees([...employees]);
    } else {
      setSelectedEmployees([]);
    }
  };

  const handleDepartmentCheck = (departmentName) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    const departmentChecked = updatedCheckedEmployees[departmentName];

    if (departmentChecked) {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = false;
      });
    } else {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = true;
      });
    }

    updatedCheckedEmployees[departmentName] = !departmentChecked;
    setCheckedEmployees(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployees(selectedEmployees);
    setAllChecked(selectedEmployees.length === employees.length);
  };

  const handleEmployeeCheck = (employeeId) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    updatedCheckedEmployees[employeeId] = !checkedEmployees[employeeId];
    setCheckedEmployees(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployees(selectedEmployees);
    setAllChecked(selectedEmployees.length === employees.length);
  };

  const filteredEmployeesByDepartment = Object.keys(
    employeesByDepartment
  ).reduce((filtered, departmentName) => {
    const filteredEmployees = employeesByDepartment[departmentName].filter(
      (employee) =>
        employee.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (filteredEmployees.length > 0) {
      filtered[departmentName] = filteredEmployees;
    }
    return filtered;
  }, {});

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    [{ header: 1 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  return (
    <div className="bg-[#F3F4F8] min-h-screen relative mx-6">
      <div className="flex items-center justify-between pt-6 px-10 pb-2 bg-[#fff] rounded-[25px] mt-2">
        <button className="text-[#1E293B] text-sm flex items-center gap-2">
          <IoIosArrowBack /> Буцах
        </button>
        <div className="flex items-center gap-6">
          <button
            onClick={() => setTable("questions")}
            className={`font-semibold pb-1 ${
              table === "question"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : "text-[#4B4B53]"
            }`}
          >
            Асуултууд
          </button>
          <button
            onClick={() => setTable("answers")}
            className={`font-semibold pb-1 ${
              table === "answer"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : "text-[#4B4B53]"
            }`}
          >
            Хариултууд
          </button>
        </div>
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-4">
            <button className="text-xl text-[#707070]">
              <LuEye />
            </button>
            <button className="text-xl text-[#707070]">
              <IoMdArrowBack />
            </button>
            <button className="text-xl text-[#707070]">
              <IoMdArrowForward />
            </button>
          </div>
          <div className="flex items-center gap-4">
            <button className="border border-[#CECFD3] text-[#1E293B] text-sm rounded-lg py-2 px-3">
              Хадгалах
            </button>
            <button
              onClick={() => {
                setPopUp(true);
              }}
              className="bg-[#324D72] text-[#fff] text-sm rounded-lg py-2 px-3 "
            >
              Илгээх
            </button>
          </div>
        </div>
      </div>
      <div className="pt-6">{renderActivePage()}</div>
      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl px-8 flex flex-col justify-center py-6">
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setPopUp(false);
                }}
                className="text-[#707070] text-xl mb-4"
              >
                <IoMdClose />
              </button>
            </div>
            <p className="font-semibold text-[#1E293B] mb-2">
              Нийт {points} онооноос хэдэн онооноос дээш авсан тохиолдолд
              тэнцсэн гэж үзэх вэ ?
            </p>
            <div className="flex justify-center">
              <input
                className="border border-[#D4D4D4] px-4 py-2 rounded-xl w-[340px]"
                value={requiredPoints}
                type="number"
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setRequiredPoints(value);

                  if (value > points) {
                    setWarning(true);
                  } else {
                    setWarning(false);
                  }
                }}
              />
            </div>
            {warning && (
              <p className="text-red-500 text-xs mt-2 text-center">
                Required points cannot exceed available points.
              </p>
            )}
            <div className="flex items-center gap-4 justify-end mt-6">
              <button
                onClick={() => {
                  setPopUp2(true);
                  setPopUp(false);
                }}
                className="text-sm text-[#1E293B] border border-[#1E293B] rounded-lg px-3 py-1 "
              >
                Алгасах
              </button>
              <button
                onClick={() => {
                  setPopUp2(true);
                  setPopUp(false);
                }}
                className="text-sm text-[#fff] bg-[#324d72] px-3 py-1 rounded-lg"
              >
                Үргэлжлүүлэх
              </button>
            </div>
          </div>
        </div>
      )}
      {popUp2 && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#fff]  w-[800px] rounded-xl   overflow-y-auto relative px-8 py-6">
            <div className="flex justify-between mb-4">
              <button
                onClick={() => {
                  setPopUp(true);
                  setPopUp2(false);
                }}
                className="text-[#1E293B] text-sm flex items-center gap-2"
              >
                <IoIosArrowBack />
                Буцах
              </button>
              <button
                onClick={() => {
                  setPopUp2(false);
                }}
              >
                <IoIosClose className="text-4xl text-[#707070]" />
              </button>
            </div>
            <p className="text-[#1E293B] font-semibold">
              Тестэд урих ажилчдыг сонгоно уу.
            </p>
            <div className="rounded-xl px-10 py-3">
              <div className="flex my-3 relative">
                <div className="absolute left-2 top-3">
                  <FaMagnifyingGlass className="text-[#64748B]" />
                </div>
                <input
                  type="text"
                  placeholder="Ажилтны нэрээр хайх "
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="bg-[#F9F9F9] text-sm text-opacity-80 w-full border-none rounded-lg ps-8 pe-2 py-2 "
                />
              </div>

              <div className="flex justify-end gap-2 items-center my-3">
                <input
                  className="bg-[#EBEBEB] border-[#BCBCBC] rounded"
                  type="checkbox"
                  onClick={handleCheckAll}
                  checked={allChecked}
                />
                <p className="text-[#1E1E1E] text-xs">Бүгдийг сонгох</p>
              </div>

              <div className="flex items-start gap-6 justify-between">
                <div className="h-[400px] overflow-y-auto w-[55%]">
                  {Object.entries(filteredEmployeesByDepartment).map(
                    ([departmentName, departmentEmployees]) => (
                      <div className="mb-4" key={departmentName}>
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleDepartmentCheck(departmentName)
                            }
                            checked={checkedEmployees[departmentName] || false}
                            className="bg-[#EBEBEB] border-[#BCBCBC] rounded"
                          />
                          <p className="text-sm font-semibold text-[#1E1E1E] ms-2 ">
                            {departmentName}
                          </p>
                        </div>
                        {departmentEmployees.map((employee) => (
                          <div
                            className="ms-4 flex items-center mt-3"
                            key={employee.id}
                          >
                            <div>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleEmployeeCheck(employee.id)
                                }
                                checked={checkedEmployees[employee.id] || false}
                                className="bg-[#EBEBEB] border-[#BCBCBC] rounded"
                              />
                              <label className="ms-2 text-sm text-[#1E1E1E]">
                                {employee.lastName}, {employee.firstName}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
                {selectedEmployees.length !== 0 && (
                  <div className=" px-4 py-2 shadow-xl rounded-xl w-[45%]">
                    <div className="flex items-center justify-between gap-1 mb-3">
                      <p className="text-[#1E1E1E] text-sm font-semibold ">
                        Таны сонгосон ажилчид
                      </p>
                      <p className="text-[#64748B] text-xs">
                        Нийт: {selectedEmployees.length} ажилтан
                      </p>
                    </div>
                    <ul className="list-decimal ms-4 max-h-[200px] overflow-y-scroll">
                      {selectedEmployees.map((items, index) => {
                        return (
                          <li
                            key={index}
                            className="text-xs text-[#636466] font-semibold mb-1"
                          >
                            {items.lastName} {items.firstName}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setEmailShow(true);
                  setPopUp2(false);
                }}
                disabled={selectedEmployees.length === 0}
                className={`mb-2 text-[#fff] text-sm px-3 py-1 rounded-lg ${
                  selectedEmployees.length === 0
                    ? "bg-[#CECFD3]"
                    : "bg-[#324D72]"
                }`}
              >
                Үргэлжлүүлэх
              </button>
            </div>
          </div>
        </div>
      )}
      {emailShow && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#fff]  w-[800px] rounded-xl   overflow-y-auto relative px-8 py-6">
            <div className="flex justify-between mb-4">
              <button
                onClick={() => {
                  setPopUp2(true);
                  setEmailShow(false);
                }}
                className="text-[#1E293B] text-sm flex items-center gap-2"
              >
                <IoIosArrowBack />
                Буцах
              </button>
              <button
                onClick={() => {
                  setEmailShow(false);
                }}
              >
                <IoMdClose className="text-4xl text-[#707070]" />
              </button>
            </div>
            <div className="mb-2">
              <p>Урилга илгээгдэх ажичид</p>
              <div className="max-h-[120px] overflow-y-scroll mb-8 mt-2">
                {selectedEmployees.map((items, index) => {
                  return (
                    <div
                      key={index}
                      className="text-xs text-[#636466] font-semibold mb-1 flex items-center gap-2"
                    >
                      {index + 1}. {items.lastName} {items.firstName}
                    </div>
                  );
                })}
              </div>
            </div>

            <p className="text-[#1E293B] font-semibold">И-мэйлийн текст</p>

            <ReactQuill
              className="text-sm"
              theme="snow"
              modules={{ toolbar: toolbarOptions }}
              value={""}
              onChange={() => {}}
            />
            <div className="flex justify-end mt-6">
              <button className="text-[#fff] bg-[#324d72] px-3 py-1 rounded-lg">
                Илгээх
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestCreate;
