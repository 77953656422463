const MainButton = ({
  isSelected,
  label,
  onClick,
  rIcon,
  lIcon,
  color,
  labelColor,
  justify,
  customClass,
  selectedColor = "bg-main-blue", // Default selected background color
  selectedTextColor = "text-white", // Default selected text color
}) => {
  const baseStyles = "w-full p-2 text-left rounded-[10px] flex";
  const bgColor = isSelected ? selectedColor : color || "";
  const hoverBgColor = "hover:bg-[#f6f6f6]";
  const textColor = isSelected ? selectedTextColor : labelColor || "text-black";
  const hoverTextColor = "hover:text-[#666874]";
  const textCenter = "text-center";
  const lIconMargin = lIcon ? "mr-2" : "";
  justify = justify || "justify-center";

  return (
    <button
      onClick={onClick}
      className={`${baseStyles} ${bgColor} ${hoverBgColor} ${textColor} ${hoverTextColor} ${textCenter} ${lIconMargin} ${justify} ${customClass}`}
    >
      <div className={`${lIconMargin}`}>{lIcon}</div>
      {label}
      {rIcon}
    </button>
  );
};

export default MainButton;
