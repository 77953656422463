import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from 'react-player/vimeo';
import Unity, { UnityContext } from "react-unity-webgl";
import { getEmployeeGameByLink, createMissingScores, createScore } from "../../services/game.service";
import { GameStepper } from '../../components/uncommon/GameStepper';
import { LoadingProgress } from '../../components/uncommon/LoadingProgress';
import { GAMES, GAME_ORDER } from '../../helper/constants';
import "./../../App.css";

const unityContext = new UnityContext({
    productName: "Games",
    companyName: "Metacog",
    loaderUrl: "../../../unity/test/unityTest.loader.js",
    dataUrl: "../../../unity/test/unityTest.data",
    frameworkUrl: "../../../unity/test/unityTest.framework.js",
    codeUrl: "../../../unity/test/unityTest.wasm",
});

const VIDEO_URLS = {
    ru: "https://vimeo.com/859366747",
    en: "https://vimeo.com/892519613",
    mn: "https://vimeo.com/836379695"
};

const EmployeeGameFlow = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    const [gameState, setGameState] = useState({
        currentStep: 0,
        loadingProgress: 0,
        showLoading: true,
        showVideo: true,
        assessmentPlayerId: null,
        gameScores: []
    });

    const [ui, setUi] = useState({
        showContinueModal: false,
        isRotated: false,
        orientation: 'portrait'
    });

    const updateOrientation = useCallback(() => {
        const isLandscape = window.innerWidth > window.innerHeight;
        setUi(prev => ({
            ...prev,
            orientation: isLandscape ? 'landscape' : 'portrait',
            isRotated: isLandscape
        }));
    }, []);

    const handleGameCompletion = useCallback(async (result) => {
        const { currentStep, assessmentPlayerId, gameScores } = gameState;
        const gameCode = GAMES[GAME_ORDER[currentStep]];
        console.log("gameState = ", GAMES, GAME_ORDER, currentStep);

        const newScore = {
            assessmentPlayerId,
            gameCode,
            values: result
        };

        console.log("newScore = ", newScore);

        try {
            await createScore(newScore);

            if (currentStep < 7) {
                setGameState(prev => ({
                    ...prev,
                    currentStep: prev.currentStep + 1,
                    gameScores: [...prev.gameScores, newScore]
                }));

                const nextGame = GAME_ORDER[currentStep + 1];
                // const langCode = i18n.language === "rn" ? 1 : i18n.language === "en" ? 2 : 0;
                unityContext.send("MetaCog", "GameLang", `${nextGame},0`);
            } else {
                await createMissingScores([...gameScores, newScore]);
                navigate('/congratz');
                unityContext.removeAllEventListeners();
            }
        } catch (error) {
            console.error('Error saving game score:', error);
            // Show error notification to user
        }
    }, [gameState, navigate, i18n.language]);

    useEffect(() => {
        const initializeGame = async () => {
            try {
                const response = await getEmployeeGameByLink({ link: id });
                const { assessmentPlayerId, playedGame = 0 } = response.data;

                if (playedGame === 8) {
                    console.log("playedGame = ", playedGame);
                    unityContext.removeAllEventListeners();
                    navigate('/congratz');
                }

                setGameState(prev => ({
                    ...prev,
                    assessmentPlayerId,
                    currentStep: playedGame,
                    showContinueModal: playedGame !== 0
                }));

            } catch (error) {
                console.error('Error initializing game:', error);
                // Show error notification
            }
        };

        initializeGame();
        window.addEventListener('resize', updateOrientation);
        updateOrientation();

        return () => {
            window.removeEventListener('resize', updateOrientation);
        };
    }, [id, updateOrientation]);

    useEffect(() => {
        unityContext.on("progress", (progression) => {
            console.log("progression = ", progression);
            if (progression === 1) {
                setGameState(prev => ({
                    ...prev,
                    loadingProgress: progression * 100,
                }));
                setTimeout(() => {
                    setGameState(prev => ({
                        ...prev,
                        loadingProgress: progression * 100,
                        showLoading: false
                    }));
                }, 2000);

                unityContext.send("MetaCog", "GameLang", `${GAME_ORDER[gameState.currentStep]},0`);
            }

            else {
                setGameState(prev => ({
                    ...prev,
                    loadingProgress: progression * 100,
                    showLoading: true
                }));
            }
        });

        unityContext.on("GameOver1", handleGameCompletion);

        return () => {
            unityContext.removeAllEventListeners();
        };
    }, [handleGameCompletion]);

    if (ui.orientation === 'portrait' && !ui.isRotated) {
        return (
            <div className="flex flex-col items-center justify-center h-full w-full top-[250px]">
                <img src="/assets/rotate.webp" alt="rotate device" />
                <p>Сайн байна уу</p>
                <p>Та утсаа эргүүлнэ үү!</p>
                <p>Баярлалаа</p>
            </div>
        );
    }

    return (
        <div className="wrapper flex h-screen grid grid-rows-8 bg-[#f6f7f8]">
            <Dialog
                open={ui.showContinueModal}
                onClose={() => setUi(prev => ({ ...prev, showContinueModal: false }))}
            >
                <DialogTitle>{t('GameFlow.doYouContinue')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('GameFlow.continueText')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUi(prev => ({ ...prev, showContinueModal: false }))}>
                        {t('GameFlow.continue')}
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="row-span-1 flex justify-center items-center z-30">
                <Box sx={{ width: "100%" }}>
                    <GameStepper
                        activeStep={gameState.currentStep}
                        language={i18n.language === "rn" ? "ru" : i18n.language}
                    />
                </Box>
            </div>

            <div className="row-span-7 m-auto">
                <div className={`pb-14 px-8 ${gameState.showLoading ? "bg-[#f6f7f8] pt-20" : "bg-white pt-8 shadow-xl"}`}>
                    <Unity className="unity-canvas" unityContext={unityContext} />
                </div>
            </div>

            {gameState.showLoading && (
                <LoadingProgress
                    progress={gameState.loadingProgress}
                    message={"Loading..."}
                />
            )}

            {gameState.showVideo && (
                <div className="fixed inset-0 bg-[#f6f7f8] z-30 flex items-center justify-center">
                    <div className="text-center px-4">
                        <div className="pb-4 text-lg md:text-2xl">
                            {'Та видео зааврыг үзэж дууссан бол '}
                            <button
                                className="font-semibold text-[#FFC5A8]"
                                onClick={() => setGameState(prev => ({ ...prev, showVideo: false }))}
                            >
                                {'ЭНД дарна уу.'}
                            </button>
                        </div>

                        <ReactPlayer
                            url={VIDEO_URLS[i18n.language] || VIDEO_URLS.mn}
                            playing
                            width="100%"
                            height="auto"
                            className="max-w-screen-md"
                            onEnded={() => setGameState(prev => ({ ...prev, showVideo: false }))}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmployeeGameFlow;