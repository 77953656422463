import React, { useEffect, useState } from "react";
import SearchModal from "./header/SearchModal";
import Notifications from "./header/Notifications";
import Help from "./header/Help";
import UserMenu from "./header/UserMenu";
import { getCompanyInfo } from "../services/player.service";
import { height } from "@mui/system";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

function Header({ sidebarOpen, setSidebarOpen }) {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [company, setCompany] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (e) => {
    const languageValue = e;
    i18n.changeLanguage(languageValue);
  };

  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30 rounded-[25px] mx-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {!photoUrl ? (
            <div
              style={{
                width: "100px",
                height: "20px",
              }}
            ></div>
          ) : (
            <div></div>
          )}
          <div className="flex items-center">
            <div>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                size="small"
                className="languageSelection bg-gray-50 rounded-[10px]"
              >
                <Select
                  defaultValue={Cookies.get("metacog_lang") || "mn"}
                  inputProps={{
                    name: "language",
                    id: "uncontrolled-native",
                  }}
                  onChange={(e) => {
                    changeLanguageHandler(e.target.value);
                    Cookies.set("metacog_lang", e.target.value, {
                      expires: 30,
                    });
                  }}
                >
                  <MenuItem value={"mn"}>
                    {/* <Box display="flex" alignItems="center"> */}
                    <span>Монгол</span>
                    {/* </Box> */}
                  </MenuItem>
                  <MenuItem value={"en"}>
                    <Box display="flex" alignItems="center">
                      <span>English</span>
                    </Box>
                  </MenuItem>
                  <MenuItem value={"rn"}>
                    <Box display="flex" alignItems="center">
                      <span>Русский</span>
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <div className="p-2 bg-[#324d72] rounded-full hover:bg-[#324d90]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5013 14.1667H4.67164C3.62295 14.1667 3.0986 14.1667 2.98829 14.0852C2.86434 13.9938 2.83406 13.9398 2.82063 13.7863C2.80869 13.6497 3.13006 13.1239 3.77281 12.0722C4.43643 10.9863 4.99993 9.40518 4.99993 7.16667C4.99993 5.92899 5.52671 4.742 6.4644 3.86684C7.40208 2.99167 8.67385 2.5 9.99993 2.5C11.326 2.5 12.5978 2.99167 13.5355 3.86684C14.4731 4.742 14.9999 5.92899 14.9999 7.16667C14.9999 9.40518 15.5634 10.9863 16.2271 12.0722C16.8698 13.1239 17.1912 13.6497 17.1792 13.7863C17.1658 13.9398 17.1355 13.9938 17.0116 14.0852C16.9013 14.1667 16.3769 14.1667 15.3282 14.1667H12.4999M7.5013 14.1667L7.49993 15C7.49993 16.3807 8.61922 17.5 9.99993 17.5C11.3806 17.5 12.4999 16.3807 12.4999 15V14.1667M7.5013 14.1667H12.4999"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}
            <hr className="w-px h-6 bg-slate-200 mx-3" />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
