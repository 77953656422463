import api from "../helper/request";

const addOna = (obj) => api.post("/ona/create", obj);

const getOnaList = () => api.get("/ona/list");

const getOnaReport = (id) => api.get(`/ona/report?onaId=${id}`);

const getOnaDetail = (id) => api.get(`/ona/detail?onaId=${id}`);

const OnaEdit = (obj) => api.post("/ona/edit", obj);

const delOna = (id) => api.delete(`/ona/${id}`);

const getOnaEmail = () => api.get("/email-template/ona");

export {
  addOna,
  getOnaList,
  getOnaReport,
  getOnaDetail,
  OnaEdit,
  getOnaEmail,
  delOna,
};
