import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React from "react";

const changeRowColor = (params) => {
  if (params.rowIndex % 2 === 0) {
    return {
      backgroundColor: "#F5F5F6",
      color: "#324D72",
      border: "none",
    };
  } else {
    return {
      color: "#324D72",
      border: "none",
    };
  }
};

const MainTable = ({ columnDefs, rowData, onRowClicked, onCellClicked }) => {
  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef: {
      sortable: true,
      filter: true,
    },
    rowClass: "ag-gred-row",
    getRowStyle: changeRowColor,
    pagination: true,
    paginationPageSize: 25,
  };

  return (
    <div className="ag-theme-alpine " style={{ width: "100%" }}>
      <AgGridReact
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        gridOptions={gridOptions}
        enableSorting={true}
        // columnDefs={columnDefs}
        rowData={rowData}
        domLayout="autoHeight" // this prop will make the grid auto-adjust its height
      />
    </div>
  );
};

export default MainTable;
