import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdOutlineEdit, MdOutlineGroup } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { delOna, getOnaList } from "../../services/ona.servise";
import Loading from "../../components/uncommon/Loading";
import { IoTrashOutline } from "react-icons/io5";

import AgCellMenu from "../../components/menu/AgCellMenu";
import MainTable from "../../components/table/MainTable";
import { toast, ToastContainer } from "react-toastify";

const Ona = () => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight - 280);
  const [height2, setHeight2] = useState(window.innerHeight / 2 - 160);
  const [ona, setOna] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [table, setTable] = useState("ongoing");
  const [endedOna, setEndedOna] = useState(null);
  const [savedOna, setSavedOna] = useState(null);
  const [loading, setLoading] = useState(true);
  const [height3, setHeight3] = useState(window.innerHeight - 100);
  const [hover, setHover] = useState(0);
  const [del, setDel] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getOnaList()
      .then((data) => setOna(data.data))
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 200);
      setHeight2(window.innerHeight / 2 - 100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }
  const clickOnCell = (e) => {
    if (e.event.button === 1) {
      window.open(`/company/ona/report/${e.data.action.id}`, "_blank");
    } else {
      navigate(`/company/ona/report/${e.data.action.id}`);
    }
  };

  const handleDelete = (id, name) => {
    delOna(id)
      .then(() => {
        const updatedList = ona.filter((item) => item.id !== id);
        setOna(updatedList);
        toast.success(`${name} has been successfully deleted.`);
        setDel(false);
      })
      .catch(() => {
        toast.error("Error.");
        setDel(false);
      });
  };

  const showDeleteConfirmation = (item) => {
    setCurrentItem(item);
    setDel(true);
  };

  return (
    <div className="w-10/12 m-auto pt-11 ">
      <div className="flex items-center justify-between">
        <p className="text-[24px] text-[#222]">{t("ona.title")}</p>
        <button
          onClick={() => {
            navigate("create");
          }}
          className="bg-[#324d72] text-sm rounded-xl px-8 py-2 font-semibold text-white flex items-center gap-2"
        >
          {t("ona.createBtn")} <FaPlus />
        </button>
      </div>
      {/* <div className="mt-4 flex items-center gap-4">
        <button
          onClick={() => {
            setTable("ongoing");
          }}
          className={`pb-2 ${
            table === "ongoing"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          {t("ona.ongoingOna")}
        </button>

        <button
          onClick={() => {
            setTable("saved");
          }}
          className={`pb-2 ${
            table === "saved"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          {t("ona.savedOna")}
        </button>
        <button
          onClick={() => {
            setTable("ended");
          }}
          className={`pb-2 ${
            table === "ended"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          {t("ona.endedOna")}
        </button>
      </div> */}
      <div className="mt-6">
        <MainTable
          columnDefs={[
            {
              headerName: "Тестүүд",
              field: "text",
              sortable: true,
              onCellClicked: clickOnCell,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/text.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/house.png" },
            },
            {
              headerName: t("ona.invited"),
              field: "employeeCount",
              sortable: true,
              flex: 2,
              onCellClicked: clickOnCell,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/employee.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/location.png" },
            },

            {
              headerName: "",
              field: "action",
              sortable: false,
              cellRenderer: AgCellMenu,
              flex: 1,
              cellRendererParams: (params) => {
                let menuItems = [
                  {
                    label: (
                      <svg
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.66732 4V10.6667H2.33398V4H7.66732ZM6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667H7.33398L6.66732 0ZM9.00065 2.66667H1.00065V10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667Z"
                          fill="#636466"
                        />
                      </svg>
                    ),
                    action: "handleDelete",
                  },
                  {
                    label: <MdOutlineEdit className="text-[#636466]" />,
                    action: "handleEdit",
                  },
                ];

                return {
                  menuItems: menuItems,
                  menuActions: {
                    handleDelete: () => {
                      showDeleteConfirmation(params.data);
                    },
                    handleEdit: () => {
                      navigate(`/company/ona/edit/${params.data.action.id}`);
                    },
                  },
                };
              },
            },
          ]}
          rowData={ona?.map((elm) => {
            return {
              text: elm.name,
              id: elm.id,
              employeeCount: elm.sentEmployee,
              action: elm,
            };
          })}
        />
      </div>

      {/* {ona === null && endedOna === null && savedOna === null ? (
        <div className="h-screen flex items-center justify-center">
          <div className="flex flex-col items-center gap-4 pb-60">
            <img src="/ona/ona2.png" alt="ona" />
            <p className="text-center text-[24px] text-[#222] font-semibold">
              {t("ona.noOna2")}
            </p>
            <button
              onClick={() => {
                navigate("create");
              }}
              className="bg-[#324d72] rounded-xl px-4 py-2 font-semibold text-white flex items-center gap-2"
            >
              {t("ona.createBtn")} <FaPlus />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex w-full gap-10 items-start justify-between mt-10">
          <div className="w-1/2">
            <p className="font-semibold text-lg text-[#222] mb-6">
              {t("ona.ongoingOna")}
            </p>
            <div style={{ height: `${height}px`, overflowY: "auto" }}>
              {ona === null ? (
                <div className="h-full flex items-center justify-center">
                  <div className="flex flex-col items-center">
                    <img src="/ona/empty.png" alt="empty" />
                    <p className="text-[#1E293B] mt-2">{t("ona.noOna2")}</p>
                  </div>
                </div>
              ) : (
                <div>
                  {ona
                    .slice()
                    .reverse()
                    .map((items) => {
                      return (
                        <div
                          key={items.id}
                          className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white"
                        >
                          <div className="flex justify-between">
                            <p className="text-[#1E293B] font-semibold">
                              {items.name}
                            </p>
                            <div className="flex items-center gap-2">
                              <button
                                onClick={() => {}}
                                className={`text-sm text-[#222] opacity-70 hover:opacity-100 `}
                              >
                                <IoTrashOutline className="border border-[#222] p-0.5 rounded-full text-xl" />
                              </button>
                              <button
                                onClick={() => {
                                  navigate(`edit/${items.id}`);
                                }}
                                className={`text-sm text-[#222] opacity-70 hover:opacity-100 `}
                              >
                                <MdOutlineEdit className="border border-[#222] p-0.5 rounded-full text-xl" />
                              </button>
                            </div>
                          </div>
                          <div className="flex items-end justify-between">
                            <div>
                              <p className="text-[#636466] text-xs">
                                {t("ona.invited")}
                              </p>
                              <p className="text-xs text-[#1E293B] flex items-center">
                                <MdOutlineGroup className="h-4 w-4" />
                                <span className="font-semibold ps-0.5 pe-1">
                                  {items.sentEmployee}
                                </span>
                                Хэлтэс нэр
                              </p>
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  navigate(`report/${items.id}`);
                                }}
                                className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100"
                              >
                                {t("ona.detail")} <FaChevronRight />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <div className="mb-4">
              <p className="font-semibold text-lg text-[#222] mb-6">
                {t("ona.savedOna")}
              </p>
              <div
                className={`shadow-xl ${
                  savedOna !== null ? "rounded-xl" : "rounded-none"
                }`}
                style={{ height: `${height2}px`, overflowY: "auto" }}
              >
                {savedOna === null ? (
                  <div className=" flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">{t("ona.noOna2")}</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                      <div className="flex justify-between">
                        <p className="text-[#1E293B] font-semibold">Ona name</p>
                        <p className="text-xs text-[#636466]">2024/06/12</p>
                      </div>
                      <div className="flex items-end justify-between">
                        <div>
                          <p className="text-[#636466] text-xs">
                            {t("ona.invited")}
                          </p>
                          <p className="text-xs text-[#1E293B] flex items-center">
                            <MdOutlineGroup className="h-4 w-4" />
                            <span className="font-semibold ps-0.5 pe-1">
                              25
                            </span>
                            Хэлтэс нэр
                          </p>
                        </div>
                        <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                          {t("ona.detail")} <FaChevronRight />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <p className="font-semibold text-lg text-[#222] mb-6">
                {t("ona.endedOna")}
              </p>
              <div
                className={`shadow-xl ${
                  endedOna === null ? "rounded-xl" : "rounded-none"
                }`}
                style={{ height: `${height2}px`, overflowY: "auto" }}
              >
                {endedOna === null ? (
                  <div className=" flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">{t("ona.noOna2")}</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                      <div className="flex justify-between">
                        <p className="text-[#1E293B] font-semibold">Ona name</p>
                        <p className="text-xs text-[#636466]">2024/06/12</p>
                      </div>
                      <div className="flex items-end justify-between">
                        <div>
                          <p className="text-[#636466] text-xs">
                            {t("ona.invited")}
                          </p>
                          <p className="text-xs text-[#1E293B] flex items-center">
                            <MdOutlineGroup className="h-4 w-4" />
                            <span className="font-semibold ps-0.5 pe-1">
                              25
                            </span>
                            Хэлтэс нэр
                          </p>
                        </div>
                        <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                          {t("ona.detail")} <FaChevronRight />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )} */}
      {del && currentItem && (
        <div
          onClick={() => {
            setDel(false);
          }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30"
        >
          <div className="bg-white rounded-xl px-8 w-[391px] h-[222px] flex flex-col justify-center">
            <p className="text-[#1E293B] text-center">
              Та <span className="font-semibold">"{currentItem.text}"</span> ийг
              устгахдаа итгэлтэй байна уу.
            </p>
            <div className="mt-4">
              <button
                onClick={() => {
                  console.log(currentItem.id);
                  handleDelete(currentItem.id, currentItem.text);
                }}
                className="w-full rounded-xl py-2 bg-[#2D2D2D] text-white text-sm mb-2 hover:opacity-70"
              >
                Устгах
              </button>
              <button
                onClick={() => {
                  setDel(false);
                }}
                className="w-full rounded-xl py-2 bg-[#E5E7EB] text-[#6B7280] text-sm mb-2 hover:opacity-70"
              >
                Болих
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Ona;
