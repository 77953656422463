import api from "../helper/request";

const findBurnoutPlayer = (str) => api.get(`/burnout/${str}`);

const createBurnoutPlayer = (obj) => api.post("/burnout", obj);

const getBurnoutCompanyOtherPlayer = () => api.get("/burnout/company/other");

const getAnyBurnOutPlayers = () => api.get("/burnout/company/info/report");

const getBurnOutPlayerData = (id) =>
  api.get(`/burnout/player/info/report/${id}`);

const takePlayerData = (obj) => api.post("/burnout/player/info", obj);

const updateBurnoutPlayerForRD = (obj) =>
  api.post("/burnout/player/update-info", obj);

const createQrCodePlayer = (obj) => api.post("/burnout/player/qr/create", obj);

const exportCreateBurnout = (obj) =>
  api.post("/burnout/import/excel", obj, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const getBurnOutDepartment = (burnoutId, departmentId) => {
  let url = "/burnout/detail?";
  if (burnoutId !== null && departmentId === null) {
    url += `burnoutId=${burnoutId}`;
  } else if (departmentId !== null && burnoutId === null) {
    url += `departmentId=${departmentId}`;
  } else {
    url += `burnoutId=${burnoutId}&departmentId=${departmentId}`;
  }

  return api.get(url);
};

const createBurnout = (obj) => api.post("/burnout/create", obj);
const takeReport = () => api.get("/burnout/report");
const takeList = () => api.get("/burnout/list");
const getBurnoutEmail = () => api.get("/email-template/burnout");
const getBurnoutGraph = (departmentId) => {
  let url = "/burnout/report/month";

  if (departmentId !== undefined) {
    url += `?depId=${departmentId}`;
  }

  console.log("url", url);
  console.log("departmentId", departmentId);
  return api.get(url);
};

const delBurnout = (id) => api.delete(`/burnout/${id}`);

export {
  findBurnoutPlayer,
  createBurnoutPlayer,
  getBurnoutCompanyOtherPlayer,
  getAnyBurnOutPlayers,
  getBurnOutPlayerData,
  takePlayerData,
  updateBurnoutPlayerForRD,
  createQrCodePlayer,
  exportCreateBurnout,
  createBurnout,
  takeReport,
  takeList,
  getBurnOutDepartment,
  getBurnoutEmail,
  getBurnoutGraph,
  delBurnout,
};
