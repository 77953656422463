import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdOutlineGroup } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa6";
import { delBurnout, takeList } from "../../../services/burnout.service";
import Loading from "../../../components/uncommon/Loading";
import { toast, ToastContainer } from "react-toastify";
import AgCellMenu from "../../../components/menu/AgCellMenu";
import MainTable from "../../../components/table/MainTable";

const MainEmployeeTable = () => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [list, setList] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [del, setDel] = useState(false);

  useEffect(() => {
    takeList()
      .then((data) => {
        setList(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (list === null) {
    return <Loading />;
  }

  const clickOnCell = (e) => {
    // if (e.event.button === 1) {
    //   window.open(`/company/ona/report/${e.data.action.id}`, "_blank");
    // } else {
    //   navigate(`/company/ona/report/${e.data.action.id}`);
    // }
  };

  const handleDelete = (id, name) => {
    delBurnout(id)
      .then(() => {
        const updatedList = list.filter((item) => item.id !== id);
        setList(updatedList);
        toast.success(`${name} has been successfully deleted.`);
        setDel(false);
      })
      .catch(() => {
        toast.error("Error.");
        setDel(false);
      });
  };

  const showDeleteConfirmation = (item) => {
    setCurrentItem(item);
    setDel(true);
  };

  return (
    <div className=" pt-11 relative">
      <MainTable
        columnDefs={[
          {
            headerName: "Тестүүд",
            field: "text",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/house.png" },
          },
          {
            headerName: "Үүсгэсэн огноо",
            field: "createdDate",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/calendar.png" },
          },
          {
            headerName: "Давталт",
            field: "interval",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/calendar.png" },
          },
          {
            headerName: "Статус",
            field: "status",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  {console.log(hprops)}

                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/calendar.png" },
          },

          {
            headerName: "",
            field: "action",
            sortable: false,
            cellRenderer: AgCellMenu,
            flex: 1,
            cellRendererParams: (params) => {
              let menuItems = [
                {
                  label: (
                    <svg
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66732 4V10.6667H2.33398V4H7.66732ZM6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667H7.33398L6.66732 0ZM9.00065 2.66667H1.00065V10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667Z"
                        fill="#636466"
                      />
                    </svg>
                  ),
                  action: "handleDelete",
                },
              ];

              return {
                menuItems: menuItems,
                menuActions: {
                  handleDelete: () => {
                    showDeleteConfirmation(params.data);
                  },
                },
              };
            },
          },
        ]}
        rowData={list?.map((elm) => {
          return {
            text: elm.name,
            id: elm.id,
            createdDate: elm.createdDate,
            interval: elm.interval,
            status: elm.isActive ? "Active" : "InActive",
            action: elm,
          };
        })}
      />
      {del && currentItem && (
        <div
          onClick={() => {
            setDel(false);
          }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30"
        >
          <div className="bg-white rounded-xl px-8 w-[391px] h-[222px] flex flex-col justify-center">
            <p className="text-[#1E293B] text-center">
              Та <span className="font-semibold">"{currentItem.text}"</span> ийг
              устгахдаа итгэлтэй байна уу.
            </p>
            <div className="mt-4">
              <button
                onClick={() => {
                  console.log(currentItem.id);
                  handleDelete(currentItem.id, currentItem.text);
                }}
                className="w-full rounded-xl py-2 bg-[#2D2D2D] text-white text-sm mb-2 hover:opacity-70"
              >
                Устгах
              </button>
              <button
                onClick={() => {
                  setDel(false);
                }}
                className="w-full rounded-xl py-2 bg-[#E5E7EB] text-[#6B7280] text-sm mb-2 hover:opacity-70"
              >
                Болих
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default MainEmployeeTable;
