import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SoftSkillReport = () => {
  const [hover, setHover] = useState(0);
  const navigate = useNavigate();
  const data = [
    ["32", "25", "32", "12", "76", "28", "25", "96"],
    ["25", "32", "32", "32", "28", "32", "25", "32"],
    ["32", "32", "32", "76", "32", "32", "32", "37"],
    ["32", "32", "32", "32", "32", "32", "32", "37"],
    ["32", "32", "32", "32", "28", "25", "32", "37"],
    ["32", "32", "32", "76", "32", "32", "32", "37"],
    ["32", "32", "32", "12", "32", "32", "32", "37"],
    ["32", "32", "32", "32", "32", "32", "32", "37"],
    ["32", "32", "32", "76", "32", "32", "32", "37"],
    ["32", "32", "32", "32", "32", "32", "32", "32"],
    ["32", "32", "32", "28", "32", "32", "32", "32"],
    ["32", "32", "32", "12", "32", "32", "32", "32"],
    ["32", "32", "32", "32", "32", "32", "32", "32"],
    ["32", "32", "32", "76", "76", "32", "32", "32"],
    ["32", "25", "32", "76", "32", "96", "32", "32"],
  ];

  const headers = [
    "Санхүү",
    "IT",
    "Marketing",
    "Хэлтэс",
    "Хэлтэс",
    "Хэлтэс",
    "Хэлтэс",
    "Хэлтэс",
  ];
  const rows = [
    "MPI оноо",
    "CI оноо",
    "Ерөнхийлөн дүгнэх",
    "Нарийвчлан дүгнэх",
    "Тоон сэтгэлгээ",
    "Анхаарал",
    "Ажлын санах ой",
    "Боловсруулах хурд",
    "Хэв таних",
    "Нухацтай сэтгэлгээ",
    "Шийдвэр гаргах чадвар",
    "Төлөвлөх чадвар",
    "Суралцах хурд",
    "Сониуч зан",
    "Эрсдэл хүлээх чадвар",
    "Дасан зохицох чадвар",
    "Тууштай байдал",
    "Зорилго төлөвлөлт",
    "Багаар ажиллах чадвар",
    "Бусдад итгэх хандлага",
    "Өөртөө итгэлтэй, шийдэмгий байдал",
  ];

  if (!data || !rows || !headers) {
    return <div>Loading...</div>;
  }

  return (
    <div className="pt-11">
      <div className="flex items-center gap-10">
        <div className="w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3">
          <div>
            <p className="text-sm text-[#666874] mb-2">Дундаж MPI оноо</p>
            <p className="text-[24px] font-semibold text-[#324D72]">52</p>
          </div>
        </div>
        <div className="w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3">
          <div>
            <p className="text-sm text-[#666874] mb-2">Дундаж CI оноо</p>
            <p className="text-[24px] font-semibold text-[#324D72]">52</p>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <p className="text-[#1E293B] font-semibold">
          Хэлтэс хоорондын харьцуулалт
        </p>
        <p className="text-sm text-[#777985]">
          Хэлтэс тус бүр дээр дарж тухайн хэлтсийн ажилчдын үзүүлэлтийг харах
          боломжтой.
        </p>

        <div className="overflow-x-auto my-10">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-3 py-1 text-left text-sm font-semibold text-[#1E293B] uppercase tracking-wider"></th>
                {rows.map((header, index) => (
                  <th
                    key={index}
                    className={`px-3 py-1 text-sm font-semibold text-[#1E293B] uppercase tracking-wider bg-none text-center`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {headers.map((row, rowIndex) => (
                <tr
                  onMouseEnter={() => {
                    setHover(rowIndex + 1);
                  }}
                  onMouseLeave={() => {
                    setHover(0);
                  }}
                  key={rowIndex}
                >
                  <td className="px-6 py-4 whitespace-nowrap  hover:bg-[#E9EDF5] text-sm font-medium text-gray-900">
                    {row}
                  </td>
                  {data[rowIndex]?.map((cell, cellIndex) => (
                    <td key={cellIndex} className={`px-1 whitespace-nowrap`}>
                      <div
                        className={`py-3 ${
                          cell === "25"
                            ? "bg-[#FFE070]"
                            : cell === "12"
                            ? "bg-[#FF5F6E]"
                            : cell === "76"
                            ? "bg-[#00D97D]"
                            : ""
                        }`}
                      >
                        <p className="text-center  text-sm text-[#324D72] font-bold ">
                          {cell}
                        </p>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SoftSkillReport;
