import api from "../helper/request";

const createOnboard = (obj) => api.post(`/onboard/create`, obj);

const getOnboardList = () => api.get(`/onboard`);

const editOnboard = (id) => api.get(`/onboard/detail?onboardId=${id}`);

const editedOnboard = (obj) => api.post(`/onboard/assign-emp`, obj);

const onboardReport = (id) => api.get(`/onboard/employee?employeeId=${id}`);

const getOnboardEmp = () => api.get(`/onboard/employee/list`);

const delOnboard = (id) => api.delete(`/onboard/${id}`);

const getDetail = (id, oId) =>
  api.get(`/onboard/employee/detail?employeeId=${id}&onboardId=${oId}`);

export {
  createOnboard,
  getOnboardList,
  editOnboard,
  editedOnboard,
  onboardReport,
  getDetail,
  getOnboardEmp,
  delOnboard,
};
