import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar, Scatter } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import {
  getBurnOutPlayerData,
  takePlayerData,
} from "../../../services/burnout.service";
import { useTranslation } from "react-i18next";
import { RxDotFilled } from "react-icons/rx";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../../components/uncommon/Loading";

const BurnOutReportDemo = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { endDate } = useParams();
  const location = useLocation();
  const { employeeData } = location.state;
  const [datas, setDatas] = useState(null);
  const [belowThree, setBelowThree] = useState([]);
  const skills = ["cognitive", "mental", "physical"];
  const [aiSug, setAiSug] = useState(null);
  const [ai, setAi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    currentJobTime: null,
    currentCompanyTime: null,
  });

  useEffect(() => {
    getBurnOutPlayerData(id)
      .then((data) => {
        setDatas(data.data);
        const belowThreeSkills = [];
        for (const skill of Object.keys(data.data)) {
          if (skills.includes(skill) && data.data[skill] < 3) {
            belowThreeSkills.push(skill);
          }
        }
        setBelowThree(belowThreeSkills);
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    if (ai !== null) {
      const dataLines = ai.split("\n").filter((line) => line.trim() !== "");
      setAiSug(dataLines);
    } else if (datas?.aiSuggest !== null) {
      const dataLines = datas?.aiSuggest
        .split("\n")
        .filter((line) => line.trim() !== "");
      setAiSug(dataLines);
    } else {
      setAi(null);
      setAiSug(null);
    }
  }, [ai, datas]);

  if (employeeData === undefined && datas === null) {
    return (
      <>
        <div className="m-auto w-10/12 pt-11">
          <div className="min-h-full flex items-center justify-center">
            <div>
              <div className="relative">
                <img
                  src="/comingSoonImg/dog.gif"
                  className="w-[300px] h-[300px] mt-20"
                  alt="coming soon img"
                />
                <div className="absolute w-[304px] h-[224px] top-[-120px] right-[-220px]">
                  <img
                    src="/comingSoonImg/CloudShapeLoading.svg"
                    alt="coming soon text"
                  />
                </div>
                <div className="absolute bottom-[60px] w-[150px] right-1/4">
                  <div className="relative">
                    <img src="/comingSoonImg/place.svg" alt="place" />
                    <div className="bottom-1.5 left-px absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="15"
                        viewBox="0 0 13 15"
                        fill="none"
                      >
                        <path
                          d="M5.90576 14.1407C7.16593 9.76172 10.1021 1.11716 11.7654 1.57089C13.4287 2.02463 8.55201 10.1398 5.90576 14.1407Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.69471 14.8527C5.65631 10.4159 3.99777 1.43824 5.67086 1.02207C7.34395 0.605905 7.05055 10.0691 6.69471 14.8527Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.94727 14.1407C5.6871 9.76172 2.75094 1.11716 1.08765 1.57089C-0.575648 2.02463 4.30102 10.1398 6.94727 14.1407Z"
                          fill="#32A675"
                        />
                      </svg>
                    </div>
                    <div className="absolute right-1 bottom-1.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="35"
                        viewBox="0 0 18 35"
                        fill="none"
                      >
                        <path
                          d="M5.90576 34.1407C7.16593 29.7617 10.1021 21.1172 11.7654 21.5709C13.4287 22.0246 8.55201 30.1398 5.90576 34.1407Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.69471 34.8527C5.65631 30.4159 3.99777 21.4382 5.67086 21.0221C7.34395 20.6059 7.05055 30.0691 6.69471 34.8527Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.94727 34.1407C5.6871 29.7617 2.75094 21.1172 1.08765 21.5709C-0.575648 22.0246 4.30102 30.1398 6.94727 34.1407Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.37012 29.9741C6.40595 23.4119 6.91866 19.7716 8.97119 13.3682"
                          stroke="#32A675"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M16.2922 7.21658C16.9248 3.23134 12.8461 -0.831332 8.98672 3.9935C6.05271 -0.261686 1.19169 1.57596 1.61088 6.78833C1.68796 7.74675 2.17797 8.61271 2.81063 9.33676L8.98672 16.4049L15.3345 9.03343C15.7896 8.50498 16.1829 7.90535 16.2922 7.21658Z"
                          fill="#FFC5A8"
                          stroke="#FFC5A8"
                          strokeOpacity="0.1"
                          strokeWidth="2.46201"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const renderStatus = () => {
    switch (employeeData?.burnoutLevel) {
      case null:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>No result</p>
          </div>
        );
      case 1:
        return (
          <div>
            <div className="flex justify-center">
              <img className="w-16" src="/burnout/1.svg" alt="icon" />
            </div>
            <p className="text-center">Burnout</p>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="flex justify-center">
              <img className="w-16" src="/burnout/2.svg" alt="icon" />
            </div>
            <p className="text-center">Disengaged</p>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="flex justify-center">
              <img className="w-16" src="/burnout/3.svg" alt="icon" />
            </div>
            <p className="text-center">Overextended</p>
          </div>
        );
      case 4:
        return (
          <div>
            <div className="flex justify-center">
              <img className="w-16" src="/burnout/4.svg" alt="icon" />
            </div>
            <p className="text-center">Ineffective</p>
          </div>
        );
      case 5:
        return (
          <div>
            <div className="flex justify-center">
              <img className="w-16" src="/burnout/5.svg" alt="icon" />
            </div>
            <p className="text-center">Engaged</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>Хариу гараагүй</p>
          </div>
        );
    }
  };

  const renderBurnoutText = () => {
    switch (employeeData?.burnoutLevel) {
      case null:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>No result</p>
          </div>
        );
      case 1:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji1.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Burned-Out</div>
            </div>
            <div className="mt-7 font-bold">Сэтгэл зүйн тусламж:</div>
            <div className="mt-4 ml-4">
              Сэтгэл зүйн тусламж авах боломжийг санал болгох. Үүнд зөвлөгөө
              өгөх үйлчилгээ, стрессийн амралт эсвэл АХХШ-н мэргэжилтнүүд рүү
              шилжүүлэх зэрэг багтаж болно.{" "}
            </div>
            <div className="mt-7 font-bold">Байгууллагын хяналт, өөрчлөлт:</div>
            <div className="mt-4 ml-4">
              Ядаргаанд хүргэж буй системийн асуудлуудыг тодорхойлж,
              шийдвэрлэхийн тулд өдөр тутмын ажил, соёлыг хянана. Үүнд
              удирдлагын арга барилд өөрчлөлт турших, ажлын ачааллыг хуваарилах
              эсвэл ажлын байрны эргономикийг сайжруулах зэрэг орно.
            </div>
            <div className="mt-7 font-bold">Ротаци:</div>
            <div className="mt-4 ml-4">
              Хэрвээ компани дотроо Rotation буюу өөр хэлтэс баг руу шилжин
              ажиллах боломжтой бол тухайн хүнтэй ганцаарчилсан уулзалт хийж энэ
              талаар ярилцах
            </div>
            <div className="mt-7 font-bold">Тогтмол санал асуулга авах:</div>
            <div className="mt-4 ml-4">
              Ажилчдын сайн сайхан байдал, сэтгэл ханамжийг хянахын тулд тогтмол
              судалгаа эсвэл албан бус шалгалт авч байх
            </div>
          </div>
        );
      case 2:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji2.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Disengaged</div>
            </div>
            <div className="mt-7 font-bold">
              Оролцоотой байдлыг нэмэгдүүлэх:
            </div>
            <div className="mt-4 ml-4">
              Эдгээр ажилчдыг юу идэвхтэй болгодгийг тодорхойл. Энэ нь илүү
              сорилт шаардсан ажил, ахих боломж, эсвэл урамшуулал гэх мэт байж
              болно.
            </div>
            <div className="mt-7 font-bold">Тогтмол ярилцах:</div>
            <div className="mt-4 ml-4">
              Шууд удирдлагатай ганцаарчилсан уулзалтууд хийснээр тэдний
              асуудал, ахиц дэвшил, ажил мэргэжлийн хүсэл тэмүүллийн талаар
              ярилцах нь маш үр дүнтэй.
            </div>
            <div className="mt-7 font-bold">Баг:</div>
            <div className="mt-4 ml-4">
              Хамгийн сүүлд багаараа хэзээ гарсан талаар асуугаарай. Тэднийг
              багийнхантайгаа илүү ойр болоход нь туслах хэрэгтэй .
            </div>
            <div className="mt-7 font-bold">Шалтгааныг олох:</div>
            <div className="mt-4 ml-4">
              Хэрэв бусдаас өөрийгөө тусгаарласан байвал үндсэн шалтгааныг
              ойлгохын тулд ганцаарчилсан ярилцлага хийх хэрэгтэй болдог.
            </div>
          </div>
        );
      case 3:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji3.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Overextended</div>
            </div>
            <div className="mt-7 font-bold">Ачааллын удирдлага:</div>
            <div className="mt-4 ml-4">
              Тэдний ажлын ачааллыг хянаж, тохируулах. Даалгавруудыг дахин
              хуваарилах арга зам хайх эсвэл нэмэлтээр юу хэрэгтэй байгааг
              үнэлээрэй
            </div>
            <div className="mt-7 font-bold">Ээлжийн амралт:</div>
            <div className="mt-4 ml-4">
              Хэзээ хамгийн сүүлд ээлжийн амралт авсаныг нь асууж үзээрэй.
            </div>
            <div className="mt-7 font-bold">Цагийн менежмент:</div>
            <div className="mt-4 ml-4">
              Тэднийг даалгавраа эрэмбэлэх, цагийг үр дүнтэй удирдахад сурга.
              цагийн менежментийг зөв хийснээр хэт их ачаалалтай байгаа
              мэдрэмжийг бууруулж болдог.
            </div>
            <div className="mt-7 font-bold">
              Тогтмол завсарлага ба амрах арга:
            </div>
            <div className="mt-4 ml-4">
              Ажлын цагаар тогтмол завсарлага авч байхыг дэмжээрэй. Ажил дээрээ
              анхаарал төвлөрүүлэх, бясалгах гэх мэт тайвшруулах арга техникийг
              нэвтрүүлэх.
            </div>
          </div>
        );
      case 4:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji4.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Ineffective</div>
            </div>
            <div className="mt-7 font-bold">Ур чадварыг дээшлүүлэх:</div>
            <div className="mt-4 ml-4">
              Түүнд илүү үр дүнтэй ажиллахын тулд нэмэлт сургалт эсвэл нөөц
              шаардлагатай эсэхийн үнэлгээ хийх.
            </div>
            <div className="mt-7 font-bold">Зорилго тавих:</div>
            <div className="mt-4 ml-4">
              Илүү зорилготой, түүндээ төвлөрч, дараа дараагийн ахиц дэвшлийг
              хэмжихэд, хүрч болох зорилтуудыг тавихад нь туслах,
            </div>
            <div className="mt-7 font-bold">
              Ажлын байрны даалгаврыг тохируулах:
            </div>
            <div className="mt-4 ml-4">
              Хийж буй даалгаврууд нь түүний давуу тал болон сонирхолд нь нийцэж
              байгаа эсэхийг шалгахын тулд тэдний үүргийг дахин үнэл.
            </div>
            <div className="mt-7 font-bold">Хамтран ажиллахыг дэмж:</div>
            <div className="mt-4 ml-4">
              Өөрийн хувь нэмрээ оруулж чадах өөр төслүүд санал болгох замаар
              тухайн хүний хамтарч ажиллах ур чадварыг нэмэгдүүлэх боломжтой.
            </div>
          </div>
        );
      case 5:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji5.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Engaged</div>
            </div>
            <div className="mt-7 font-bold">
              Оролцоотай байдлыг нь хадгалах:
            </div>
            <div className="mt-4 ml-4">
              Тэдний хүчин чармайлт, оруулсан хувь нэмрийг хүлээн зөвшөөрч
              урашмуулах нь маш чухал юм.
            </div>
            <div className="mt-7 font-bold">Мэргэжлийн өсөлтийн боломжууд:</div>
            <div className="mt-4 ml-4">
              Ахисан түвшний сургалт, манлайллын хөгжлийн хөтөлбөр эсвэл өндөр
              түвшний төслүүд дээр ажиллах боломжийг санал болгоорой. Мөн аливаа
              мэргэжлийн хөтөлбөр эсвэл нийгэмлэгт орохыг нь дэмжээрэй.
            </div>
            <div className="mt-7 font-bold">
              Ажил амьдралын тэнцвэрт байдал:
            </div>
            <div className="mt-4 ml-4">
              Ирээдүйд ядрахаас урьдчилан сэргийлэхийн тулд тэнцвэрийг бий
              болгох. Үүнд ажлын уян хатан цаг, алсаас ажиллах сонголт эсвэл
              өмнө нь нэмэлт цагаар ажилласан бол амралт багтаж болно.
            </div>
            <div className="mt-7 font-bold">
              Эрх мэдэл ба бие даасан байдал:
            </div>
            <div className="mt-4 ml-4">
              Тэдний ажилд шийдвэр гаргах эрх мэдэл, бие даасан байдлыг
              нэмэгдүүлэх. Энэ нь өөриймсөг сэтгэлгээ, илүү сэтгэл ханамжийг бий
              болгодог.
            </div>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>Хариу гараагүй</p>
          </div>
        );
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        formData.startDate === null ||
        formData.endDate === null ||
        formData.currentCompanyTime === null ||
        formData.currentJobTime === null
      ) {
        toast.error(t("toast.fillAllField"));
        return;
      }
      setLoading(true);
      let process = 1;
      const interval = setInterval(() => {
        if (process < 100) {
          setProgress(process);
          process++;
        } else {
          clearInterval(interval);
        }
      }, 1200);

      await takePlayerData({
        id: id,
        startDate: formData.startDate,
        endDate: formData.endDate,
        currentCompanyTime: formData.currentCompanyTime,
        currentJobTime: formData.currentJobTime,
      }).then((val) => {
        console.log("val", val);
        setLoading(false);
        setAi(val.data);
        setDatas({
          ...datas,
          startDate: formData.startDate,
          endDate: formData.endDate,
          currentCompanyTime: formData.currentCompanyTime,
          currentJobTime: formData.currentJobTime,
        });
      });
      toast.success(t("toast.Success"));
    } catch (error) {
      console.error("Error:", error);
      toast.error(t("toast.Error"));
    }
  };

  const data = {
    labels: ["2023.06", "2023.12", "2024.1"],
    datasets: [
      {
        label: "Values",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 99, 132, 0.4)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        barThickness: 10,
        data: [4, 2, 1],
      },
    ],
  };
  const options = {
    scales: {
      y: {
        max: 5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="m-auto w-10/12 pt-11">
      <div className="flex items-start justify-between">
        <div className="border w-[292px]  rounded-xl shadow-xl p-5">
          <div className="flex justify-center">
            <img
              className="w-20 border rounded-full mt-5"
              src="/user.svg"
              alt="img"
            />
          </div>
          <p className="text-2xl mt-10  text-[#324d72] overflow-hidden text-center">
            {(employeeData.lastName, employeeData.name)}
          </p>
          <div className="border-y  border-[#324d72] py-2 px-4 my-6 flex justify-between items-start">
            <div>
              <div className="flex items-start gap-10 mt-4">
                <p className="text-center my-2">
                  {t("burnout.eachEmployee.status")}:
                </p>
                {renderStatus()}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex md:flex-col lg:flex-row items-center gap-4 ">
            <div className="border w-[262px] h-[170px] rounded-xl border-[#F18B8B] p-8 shadow-xl">
              <p className="text-center text-lg mb-4 font-bold">
                {t("burnout.eachEmployee.consider")}
              </p>
              <div className="flex justify-center">
                <div>
                  {belowThree.length === 0 ? (
                    <p className="text-start font-bold text-sm">байхгүй</p>
                  ) : (
                    <div>
                      {belowThree.map((item) => {
                        let skillLabel;
                        if (item === "mental") {
                          skillLabel = t(
                            "burnout.generalReport.generalPsychologic"
                          );
                        } else if (item === "physical") {
                          skillLabel = t("burnout.generalReport.fatigue");
                        } else if (item === "cognitive") {
                          skillLabel = t(
                            "burnout.generalReport.intellectualProductivity"
                          );
                        } else {
                          skillLabel = item;
                        }
                        return (
                          <p
                            key={item}
                            className="text-start font-bold text-lg"
                          >
                            {skillLabel}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border w-[262px] h-[170px] p-7 rounded-xl shadow-xl">
              <p className="text-lg mb-4 text-center font-bold">
                {t("burnout.eachEmployee.workDuration")}
              </p>
              {datas?.currentCompanyTime === null ? (
                <select
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      currentCompanyTime: parseInt(e.target.value, 10),
                    })
                  }
                  className="mt-4 rounded-lg"
                >
                  <option value="" disabled selected>
                    {t("burnout.eachEmployee.select")}
                  </option>
                  <option value="6">
                    {i18n.language === "mn"
                      ? "6 сар хүртэлх"
                      : "Up to 6 months"}
                  </option>
                  <option value="11">
                    {i18n.language === "mn" ? "1 жил хүртэлх" : "Up to 1 year"}
                  </option>
                  {Array.from({ length: 20 }, (_, index) => (
                    <option key={index + 1} value={(index + 1) * 12}>
                      {index + 1} {t("burnout.eachEmployee.years")}
                    </option>
                  ))}
                  <option value="21">
                    {i18n.language === "mn"
                      ? "20 оос дээш жил"
                      : "more than 20 years"}
                  </option>
                </select>
              ) : (
                <div>
                  {datas?.currentCompanyTime === 6 ? (
                    <p className="text-sm text-center">
                      {i18n.language === "mn"
                        ? "6 сар хүртэлх"
                        : "Up to 6 months"}
                    </p>
                  ) : datas?.currentCompanyTime === 11 ? (
                    <p className="text-sm text-center">
                      {i18n.language === "mn"
                        ? "1 жил хүртэлх"
                        : "Up to 1 year"}
                    </p>
                  ) : datas?.currentCompanyTime === 21 ? (
                    <p className="text-sm text-center">
                      {" "}
                      {i18n.language === "mn"
                        ? "20 оос дээш жил"
                        : "more than 20 years"}
                    </p>
                  ) : (
                    <p className="text-sm text-center">
                      {Number(datas?.currentCompanyTime) / 12}{" "}
                      {t("burnout.eachEmployee.years")}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex md:flex-col-reverse lg:flex-row items-center gap-4 mt-5">
            <div className="border w-[262px] h-[170px] shadow-xl rounded-xl p-7 ">
              <p className="text-center text-lg mb-4">
                {t("burnout.eachEmployee.lastVacationDate")}
              </p>
              <div className="flex items-center justify-center gap-2">
                {datas?.startDate === null ? (
                  <input
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        startDate: e.target.value,
                      })
                    }
                    type="date"
                    className="rounded-lg text-xs p-1 "
                  />
                ) : (
                  <p className="text-sm">{datas?.startDate}</p>
                )}
                <p> ~ </p>
                {datas?.endDate === null ? (
                  <input
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        endDate: e.target.value,
                      })
                    }
                    type="date"
                    className="rounded-lg text-xs p-1 "
                  />
                ) : (
                  <p className="text-sm">{datas?.endDate}</p>
                )}
              </div>
            </div>
            <div className="border w-[262px] h-[170px] p-7 rounded-xl shadow-xl">
              <p className="text-lg mb-4 text-center font-bold">
                {t("burnout.eachEmployee.positionDuration")}
              </p>
              {datas?.currentJobTime === null ? (
                <select
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      currentJobTime: parseInt(e.target.value, 10),
                    })
                  }
                  className="mt-4 rounded-lg"
                >
                  <option value="" disabled selected>
                    {t("burnout.eachEmployee.select")}
                  </option>
                  <option value="6">
                    {i18n.language === "mn"
                      ? "6 сар хүртэлх"
                      : "Up to 6 months"}
                  </option>
                  <option value="11">
                    {i18n.language === "mn" ? "1 жил хүртэлх" : "Up to 1 year"}
                  </option>
                  {Array.from({ length: 20 }, (_, index) => (
                    <option key={index + 1} value={(index + 1) * 12}>
                      {index + 1} {t("burnout.eachEmployee.years")}
                    </option>
                  ))}
                  <option value="21">
                    {i18n.language === "mn"
                      ? "20 оос дээш жил"
                      : "more than 20 years"}
                  </option>
                </select>
              ) : (
                <div>
                  {datas?.currentJobTime === 6 ? (
                    <p className="text-sm text-center">
                      {i18n.language === "mn"
                        ? "6 сар хүртэлх"
                        : "Up to 6 months"}
                    </p>
                  ) : datas?.currentJobTime === 11 ? (
                    <p className="text-sm text-center">
                      {i18n.language === "mn"
                        ? "1 жил хүртэлх"
                        : "Up to 1 year"}
                    </p>
                  ) : datas?.currentJobTime === 21 ? (
                    <p className="text-sm text-center">
                      {i18n.language === "mn"
                        ? "20 оос дээш жил"
                        : "more than 20 years"}
                    </p>
                  ) : (
                    <p className="text-sm text-center">
                      {Number(datas?.currentJobTime) / 12}{" "}
                      {t("burnout.eachEmployee.years")}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center my-10">
        <div className="p-7 w-[282px] h-[164px] flex flex-col justify-between text-lg text-[#324d72] font-bold text-center shadow-xl rounded-lg">
          <p>
            {" "}
            {t("burnout.generalReport.fatigue")} - {datas?.physical.toFixed(1)}
          </p>

          <div>
            <img className="mt-7" src="/burnoutRe.png" alt="emote" />
            <input
              min={1}
              max={5}
              step={0.1}
              value={datas?.physical}
              type="range"
              className="w-full"
              readOnly
            />
          </div>
        </div>
        <div className="p-7 w-[282px] h-[164px] flex flex-col justify-between text-lg text-[#324d72] font-bold text-center shadow-xl rounded-lg">
          <p>
            {t("burnout.generalReport.generalPsychologic")} -{" "}
            {datas?.mental.toFixed(1)}
          </p>
          <div>
            <img src="/burnoutRe.png" alt="emote" />
            <input
              type="range"
              className="w-full"
              value={datas?.mental}
              min={1}
              max={5}
              step={0.1}
              readOnly
            />
          </div>
        </div>

        <div className="p-7 w-[282px] h-[164px] flex flex-col justify-between text-lg text-[#324d72] font-bold text-center shadow-xl rounded-lg">
          <p>
            {t("burnout.generalReport.intellectualProductivity")} -{" "}
            {datas?.cognitive.toFixed(1)}{" "}
          </p>
          <div>
            <img className="mt-7" src="/burnoutRe.png" alt="emote" />
            <input
              type="range"
              className="w-full"
              value={datas?.cognitive}
              min={1}
              max={5}
              step={0.1}
              readOnly
            />
          </div>
        </div>
      </div>
      {aiSug !== null || loading === true ? (
        ""
      ) : (
        <div
          className={`mt-[80px] ${
            loading === true ? "hidden" : "flex"
          } items-center justify-between`}
        >
          <p className="text-sm">{t("burnout.eachEmployee.titleOfAi")}</p>
          <button
            onClick={handleSubmit}
            className={`p-2 border-2  borde-[#666874] rounded-xl shadow-xl ${
              ai === null ? "flex" : "hidden"
            } items-center hover:opacity-50`}
          >
            <svg
              width="26"
              height="22"
              viewBox="0 0 26 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5742 20.7455L7.12744 21.154L7.59949 16.4398L18.9287 6.69692C19.0979 6.54725 19.2998 6.42833 19.5228 6.34713C19.7455 6.26594 19.985 6.22412 20.2268 6.22412C20.4687 6.22412 20.7082 6.26594 20.9311 6.34713C21.1541 6.42833 21.356 6.54725 21.525 6.69692L23.8308 8.70835C24.0009 8.85444 24.136 9.02824 24.2282 9.21974C24.3204 9.41123 24.3678 9.61662 24.3678 9.82406C24.3678 10.0315 24.3204 10.2369 24.2282 10.4284C24.136 10.6199 24.0009 10.7937 23.8308 10.9398L12.5742 20.7455Z"
                stroke="#324D72"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.52815 6.24167C0.891007 6.14572 0.891007 5.35408 1.52815 5.25815C3.83641 4.91056 5.6723 3.38885 6.17333 1.40791L6.21172 1.25606C6.34956 0.711038 7.24619 0.707645 7.38953 1.2516L7.43615 1.42856C7.95572 3.40017 9.79211 4.90902 12.094 5.25563C12.7344 5.35206 12.7344 6.14775 12.094 6.24417C9.79211 6.59078 7.95572 8.09964 7.43615 10.0712L7.38953 10.2482C7.24619 10.7922 6.34956 10.7888 6.21172 10.2437L6.17333 10.0919C5.6723 8.11095 3.83641 6.58924 1.52815 6.24167Z"
                stroke="#324D72"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="ms-2 text-[#324d72]">
              {t("burnout.eachEmployee.aiBtn")}
            </p>
          </button>
        </div>
      )}
      {loading ? (
        <div className="mt-[80px]">
          <p className={`${progress > 50 ? "block" : "hidden"}`}>
            {t("burnout.eachEmployee.loadingRes")}
          </p>
          <p className={`${progress <= 50 ? "block" : "hidden"}`}>
            {t("burnout.eachEmployee.loadingRes2")}
          </p>
          <div
            className="progress mt-4 rounded-lg"
            style={{
              width: "300px",
              height: "20px",
              border: "1px solid #ccc",
              position: "relative",
            }}
          >
            <div
              className="progress-bar rounded-lg"
              role="progressbar"
              style={{
                width: `${progress}%`,
                backgroundColor: "blue",
                height: "100%",
              }}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <p
                className="absolute -top-1.5 left-0 mt-1 ml-1"
                style={{
                  left: `${progress * 3}px`,
                }}
              >
                {progress}%
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {aiSug == null ? (
            ""
          ) : (
            <div>
              <div className="my-10 border-2 border-[#ABADB5] p-8 shadow-xl rounded-xl ">
                {aiSug.map((line, index) => (
                  <p key={index}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: line.replace(
                          /\*\*(.*?)\*\*/g,
                          "<strong>$1</strong>"
                        ),
                      }}
                    />
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default BurnOutReportDemo;
