/* eslint-disable no-template-curly-in-string */
export const baseUrl = 'http://localhost:8080';
export const clientId = 'clientApp';
export const clientSecret = 'clientSecret';
export const MGL_UPPER_CHARACTERS = [
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Е',
  'Ё',
  'Ж',
  'З',
  'И',
  'Й',
  'К',
  'Л',
  'М',
  'Н',
  'О',
  'Ө',
  'П',
  'Р',
  'С',
  'Т',
  'У',
  'Ү',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Ъ',
  'Ы',
  'Ь',
  'Э',
  'Ю',
  'Я',
];

export const validateMessages = {
  required: '"${label}" талбар заавал бөглөх хэрэгтэй!',
  types: {
    email: '"${label}" email ийн нөхцөлийг хангахгүй байна!',
    number: '"${label}" тоо биш байна!',
  },
  number: {
    range: '"${label}" ${min} ээс ${max} хооронд байна!',
  },
};

export const adminRoles = ['ROLE_ADMIN', 'ROLE_MANAGER'];

export const GAMES = [
  'ARROWS',
  'SWITCH',
  'DELIVERY',
  'NOMADLIFE',
  'TEAM',
  'CARDS',
  'WATERWELL',
  'NEIGHBORS'
];

export const GAME_ORDER = [0, 5, 2, 3, 7, 6, 4, 1];

export const STEPS = {
  mn: [
    "Тоглоом 1", "Тоглоом 2", "Тоглоом 3", "Тоглоом 4",
    "Тоглоом 5", "Тоглоом 6", "Тоглоом 7", "Тоглоом 8"
  ],
  ru: [
    "1 Игра", "2 Игра", "3 Игра", "4 Игра",
    "5 Игра", "6 Игра", "7 Игра", "8 Игра"
  ],
  en: [
    "Game 1", "Game 2", "Game 3", "Game 4",
    "Game 5", "Game 6", "Game 7", "Game 8"
  ]
}; 