import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { delOnboard } from "../../../services/onboard.service";
import MainTable from "../../../components/table/MainTable";
import AgCellMenu from "../../../components/menu/AgCellMenu";
import Loading from "../../../components/uncommon/Loading";
import { toast, ToastContainer } from "react-toastify";

const OnBoardingList = ({ list }) => {
  const [del, setDel] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [lists, setLists] = useState(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (list !== null) {
      setLists(list);
    }
  }, [list]);

  if (lists === null) {
    return <Loading />;
  }

  const clickOnCell = (e) => {
    if (e.event.button === 1) {
      window.open(`/company/onBoarding/create/${e.data.action.id}`, "_blank");
    } else {
      navigate(`/company/onBoarding/create/${e.data.action.id}`);
    }
  };

  const handleDelete = (id, name) => {
    delOnboard(id)
      .then(() => {
        const updatedList = lists.filter((item) => item.id !== id);
        setLists(updatedList);
        toast.success(`${name} has been successfully deleted.`);
        setDel(false);
      })
      .catch(() => {
        toast.error("Error.");
        setDel(false);
      });
  };

  const showDeleteConfirmation = (item) => {
    setCurrentItem(item);
    setDel(true);
  };

  return (
    <div className="w-full mb-2 mt-6 relative">
      <MainTable
        columnDefs={[
          {
            headerName: "Тестүүд",
            field: "text",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <img src="/survey/icon/text.svg" alt="icon" />
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/house.png" },
          },
          {
            headerName: "Үүсгэсэн огноо",
            field: "createdDate",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <img src="/survey/icon/created.svg" alt="icon" />
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/calendar.png" },
          },
          {
            headerName: t("Survey.question"),
            field: "questionCount",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <img src="/survey/icon/question.svg" alt="icon" />
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/chart.png" },
          },
          {
            headerName: t("Survey.participant"),
            field: "employeeCount",
            sortable: true,
            flex: 2,
            onCellClicked: clickOnCell,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <img src="/survey/icon/employee.svg" alt="icon" />
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/location.png" },
          },

          {
            headerName: "",
            field: "action",
            sortable: false,
            cellRenderer: AgCellMenu,
            flex: 1,
            cellRendererParams: (params) => {
              let menuItems = [
                {
                  label: (
                    <svg
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66732 4V10.6667H2.33398V4H7.66732ZM6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667H7.33398L6.66732 0ZM9.00065 2.66667H1.00065V10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667Z"
                        fill="#636466"
                      />
                    </svg>
                  ),
                  action: "handleDelete",
                },
              ];

              return {
                menuItems: menuItems,
                menuActions: {
                  handleDelete: () => {
                    showDeleteConfirmation(params.data);
                  },
                },
              };
            },
          },
        ]}
        rowData={[...lists].reverse().map((elm) => {
          return {
            text: elm.name,
            id: elm.id,
            createdDate: elm.createdDate?.substring(0, 16),
            questionCount: elm.questionCount,
            employeeCount: elm.employeeCount,
            action: elm,
          };
        })}
      />

      {del && currentItem && (
        <div
          onClick={() => {
            setDel(false);
          }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30"
        >
          <div className="bg-white rounded-xl px-8 w-[391px] h-[222px] flex flex-col justify-center">
            <p className="text-[#1E293B] text-center">
              Та <span className="font-semibold">"{currentItem.text}"</span> ийг
              устгахдаа итгэлтэй байна уу.
            </p>
            <div className="mt-4">
              <button
                onClick={() => {
                  console.log(currentItem.id);
                  handleDelete(currentItem.id, currentItem.text);
                }}
                className="w-full rounded-xl py-2 bg-[#2D2D2D] text-white text-sm mb-2 hover:opacity-70"
              >
                Устгах
              </button>
              <button
                onClick={() => {
                  setDel(false);
                }}
                className="w-full rounded-xl py-2 bg-[#E5E7EB] text-[#6B7280] text-sm mb-2 hover:opacity-70"
              >
                Болих
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default OnBoardingList;
