import { useEffect, useState } from "react";
import axios from "axios";
import MainInput from "../components/input/MainInput";
import { FaRegUser } from "react-icons/fa6";
import { FiLock } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { login } from "../services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContext";
import { generateCodeVerifier, generateCodeChallenge } from "./../helper/utils";
import { azureConfig } from "./../helper/authConfig";

export default function Login() {
  const [show, setShow] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { authLogin } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");

        if (response.data.country_name !== "Mongolia") {
          changeLanguageHandler("en");
        }
      } catch (error) {
        console.error("Error fetching country data: ", error);
      }
    };

    fetchCountry();
    const storedRememberMe = localStorage.getItem("rememberMe");
    console.log(localStorage);
    if (storedRememberMe === "true") {
      const storedUsername = localStorage.getItem("username");
      const storedPassword = localStorage.getItem("password");
      setUsername(storedUsername || "");
      setPassword(storedPassword || "");
      setRememberMe(true);
    }
  }, []);

  const changeLanguageHandler = (e) => {
    const languageValue = e;
    i18n.changeLanguage(languageValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    login({
      username: data.get("username"),
      password: data.get("password"),
    })
      .then((res) => {
        const { accessToken: token } = res.data;

        Cookies.set("access_token", token, { expires: 1 });
        authLogin(data.get("username"), ["premium"]);

        if (rememberMe) {
          localStorage.setItem("username", data.get("username"));
          localStorage.setItem("password", data.get("password"));
          localStorage.setItem("rememberMe", true);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }

        navigate({ pathname: "/" });
      })
      .catch((error) => {
        toast.error(t("Login.toastWrongPassword"));
      });
  };

  const redirectToLogin = async () => {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // Store code verifier in session storage for later use
    sessionStorage.setItem("pkce_code_verifier", codeVerifier);

    const params = new URLSearchParams({
      client_id: azureConfig.clientId,
      response_type: "code",
      redirect_uri: azureConfig.redirectUri,
      response_mode: "query",
      scope: azureConfig.scopes.join(" "),
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
    });

    window.location.href = `${
      azureConfig.authority
    }/oauth2/v2.0/authorize?${params.toString()}`;
  };

  return (
    <>
      <div className="min-h-screen min-w-screen flex relative">
        <div className="absolute flex left-12 top-6">
          <img
            className=" h-[20px] ms-2 mt-2"
            src="/oneplaceIcon.svg"
            alt="logo"
          />
          <p className="text-xl font-black text-[#000]">oneplace.hr</p>
        </div>
        <div className="mt-10 flex-1 flex justify-end items-center">
          <div className="ms-20">
            <div>
              <p className="font-semibold text-[50px]">{t("Login.title1")}</p>
              <p className="text-[35px]">{t("Login.title2")}</p>
              <p className="mt-10">{t("Login.desc")}</p>
              <p>
                {" "}
                <a
                  className="underline"
                  href="https://calendly.com/khurelbaatar/book-a-meeting-with-oneplace-hr"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Login.link")}
                </a>{" "}
                {t("Login.desc2")}
              </p>
            </div>
            <div className="mt-10  flex justify-end">
              <img
                src="/signup.svg"
                className="rounded-l-xl h-full"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 bg-white flex justify-center items-center">
          <div className="text-start border rounded-[30px] px-10 py-6 w-[540px]">
            <div>
              <p className="text-[#000] text-[20px] mt-4">
                {t("Login.welcome")}
              </p>
              <div className="flex justify-between items-center">
                <p className="text-[#1E293B] text-[48px]">
                  {" "}
                  {t("Login.login")}
                </p>
                <div className="relative">
                  <select
                    className="appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    defaultValue={Cookies.get("metacog_lang") || "mn"}
                    onChange={(e) => {
                      changeLanguageHandler(e.target.value);
                      Cookies.set("metacog_lang", e.target.value, {
                        expires: 30,
                      });
                    }}
                  >
                    <option value="mn">MN</option>
                    <option value="en">EN</option>
                    <option value="rn">RU</option>
                  </select>

                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>
            <form type="form" onSubmit={handleSubmit} className="mt-10">
              <div className="relative ">
                <div className="mb-5">
                  <p className="text-[#1E293B] mb-2">{t("Login.label")}</p>
                  <MainInput
                    placeholder={`${t("Login.username")}`}
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    icon={<FaRegUser />}
                  />
                </div>
                <div>
                  <p className="text-[#1E293B] mb-2">{t("Login.label2")}</p>
                  <MainInput
                    placeholder={t("Login.password")}
                    id="password"
                    name="password"
                    value={password}
                    type={show ? "text" : "password"}
                    buttonLabel={!show ? <FiEyeOff /> : <FiEye />}
                    icon={<FiLock />}
                    showButton={true}
                    onChange={(e) => setPassword(e.target.value)}
                    onClickButton={() => {
                      setShow(!show);
                    }}
                  />
                </div>
              </div>
              <div className="flex mt-4 justify-between items-center">
                <div>
                  <label className="text-gray-500 text-sm">
                    <input
                      className="me-2 rounded-md"
                      type="checkbox"
                      checked={rememberMe}
                      name="value2"
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    {t("Login.save")}
                  </label>
                </div>
                {/* <div>
                  <button className="underline text-sm text-gray-500">
                    Нууц үгээ мартсан?
                  </button>
                </div> */}
              </div>
              <div className="flex justify-center mt-10">
                <button
                  type="submit"
                  className="bg-[#324d76] text-center w-full rounded-lg py-3 text-white hover:bg-[#324d90] hover:text-gray-300"
                >
                  {t("Login.login")}
                </button>
              </div>
            </form>
            <div className="flex items-center justify-between gap-2 my-2">
              <div className="bg-[#666] opacity-25 w-[48%] h-[2px]" />
              <p className="text-[#666666]">{t("Login.or")}</p>
              <div className="bg-[#666] opacity-25 w-[48%] h-[2px]" />
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => {
                  redirectToLogin();
                }}
                className="border border-[#A5A5A5] rounded-xl px-4 py-3 bg-[#fff] text-[#000]  flex items-center justify-center w-full"
                type="submit"
              >
                <div className="flex items-center gap-2">
                  <img src="/microsoft.svg" alt="microsoft" />
                  <p className="text-[#1E293B]">{t("Login.microsoft")}</p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
