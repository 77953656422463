import * as React from "react";
import { BiSolidUser } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { useRef, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import MainInput from "../../components/input/MainInput";
import {
  AddCompanyEmployee,
  getCompanyEmployee,
  getDepartments,
  getEmployee,
  editEmployee,
  getTeams,
  getAlbas,
} from "../../services/employee.service";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { companyAssessmentPlayer } from "../../services/assessmentPlayer.service";

const AddWorker = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedImg, setselectedImg] = useState({});
  const [highOfficialName, setHighOfficialName] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [albas, setAlbas] = useState([]);
  const [albaOptions, setAlbaOptions] = useState([]);
  const [selectedDepat, setSelectedDepat] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedAlba, setSelectedAlba] = useState("");
  const [players, setPlayers] = useState([]);
  const { id } = useParams();
  const isEdit = !!id;
  const [formData, setFormData] = useState({
    profileUrl: "/user.svg",
    firstName: "",
    lastName: "",
    workStartedDate: "",
    birthDate: "",
    departmentId: "",
    albaId: "",
    highOfficial: "",
    workAddress: "",
    email: "",
    position: "",
    type: "",
    parentId: "",
    assPlayerId: "",
  });

  const departmentOptions = departments?.map((department) => ({
    name: department.name,
    id: department.id,
    companyId: department.companyId,
  }));

  //highOfficial
  useEffect(() => {
    companyAssessmentPlayer().then((res) => {
      setPlayers(res.data);
    });

    const fetchData = async () => {
      try {
        const departmentsData = await getDepartments();
        setDepartments(departmentsData.data);

        const teamsData = await getTeams();
        setTeams(teamsData.data);

        setTeamOptions(
          teamsData.data?.map((team) => ({
            name: team.name,
            id: team.id,
            companyId: team.companyId,
          }))
        );

        const albasData = await getAlbas();
        setAlbas(albasData.data);

        setAlbaOptions(
          albasData.data?.map((team) => ({
            name: team.name,
            id: team.id,
            companyId: team.companyId,
          }))
        );

        const highOfficialData = await getCompanyEmployee();
        setHighOfficialName(highOfficialData.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formData.departmentId) {
      const selectedDepartment = departmentOptions.find(
        (department) => department.id === formData.departmentId
      );
      setSelectedDepat(selectedDepartment ? selectedDepartment.name : "");
    }
  }, [formData.departmentId, departmentOptions]);

  useEffect(() => {
    if (formData.teamId) {
      const selectedTeamT = teamOptions.find(
        (team) => team.id === formData.teamId
      );
      setSelectedTeam(selectedTeamT ? selectedTeamT.name : "");
    }
  }, [formData.teamId, teamOptions]);

  useEffect(() => {
    if (formData.albaId) {
      const selectedAlbaA = albaOptions.find(
        (alba) => alba.id === formData.albaId
      );
      setSelectedAlba(selectedAlbaA ? selectedAlbaA.name : "");
    }
  }, [formData.albaId, albaOptions]);

  // zurag avah func
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 25 * 1024 * 1024) {
        toast.error(t("toast.FileSize25mb"));
        return;
      }

      setselectedImg(file);
      setFormData({ ...formData, profileUrl: file.name });
    } else {
      setFormData({ ...formData, profileUrl: "" });
    }
  };

  const Numberedid = Number(id);
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const employeeData = await getEmployee(id);
        console.log(
          "employeeData.data.birthDate = ",
          employeeData.data.birthDate
        );
        setFormData({
          id: Numberedid,
          profileUrl: employeeData.data.profileUrl,
          firstName: employeeData.data.firstName,
          lastName: employeeData.data.lastName,
          workStartedDate:
            employeeData.data.workStartedDate !== null
              ? moment(employeeData.data.workStartedDate).format("YYYY-MM-DD")
              : "",
          birthDate:
            employeeData.data.birthDate !== null
              ? moment(employeeData.data.birthDate).format("YYYY-MM-DD")
              : "",
          departmentId: employeeData.data.departmentId,
          teamId: employeeData.data.teamId,
          albaId: employeeData.data.albaId,
          highOfficial: employeeData.data.highOfficial,
          workAddress: employeeData.data.workAddress,
          email: employeeData.data.email,
          position: employeeData.data.position,
          type: employeeData.data.type,
          parentId: employeeData.data.parentId,
          assPlayerId: employeeData.data.assPlayerId,
        });
      } catch (err) {
        console.log("Error fetching employee data:", err);
      }
    };

    if (isEdit) {
      fetchEmployeeData();
    }
  }, [id, isEdit]);
  //submit func
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = [
      // "profileUrl",
      "firstName",
      "lastName",
      // "workStartedDate",
      // "birthDate",
      formData.departmentId ? "departmentId" : "departmentName",
      // formData.teamId ? "teamId" : "teamName",
      // "workAddress",
      "email",
      "position",
      "type",
    ];

    const missingFields = requiredFields.filter((field) => !formData[field]);

    const missingFieldMessages = {
      // profileUrl: t("EmployeeAdd.profilePic"),
      firstName: t("EmployeeAdd.firstname"),
      lastName: t("EmployeeAdd.lastname"),
      // workStartedDate: t("EmployeeAdd.workStartDate"),
      // birthDate: t("EmployeeAdd.birthdate"),
      // workAddress: t("EmployeeAdd.workAddress"),
      email: t("EmployeeAdd.email"),
      position: t("EmployeeAdd.Position"),
      type: t("EmployeeAdd.employeeType"),
      department: t("EmployeeAdd.department"),
    };

    if (missingFields.length > 0) {
      if (!formData.departmentId && !formData.departmentName) {
        missingFields.push("department");
      }

      const errorMessages = missingFields.map(
        (field) => missingFieldMessages[field]
      );
      errorMessages.unshift(t("EmployeeAdd.toastFill"));
      const errorMessage = errorMessages.join(", ");
      toast.error(errorMessage);
      return;
    }

    console.log("AAA = ", formData.workStartedDate, formData.birthDate);

    const formattedDate =
      formData.workStartedDate !== ""
        ? moment(formData.workStartedDate).format("YYYY-MM-DD HH:mm:ss")
        : null;

    const birthFormattedDate =
      formData.birthDate !== ""
        ? moment(formData.birthDate).format("YYYY-MM-DD HH:mm:ss")
        : null;

    try {
      if (!isEdit) {
        await AddCompanyEmployee({
          ...formData,
          workStartedDate: formattedDate,
          birthDate: birthFormattedDate,
          assPlayerId:
            formData.assPlayerId === "" ? null : formData.assPlayerId,
        });
        toast.success(t("EmployeeAdd.toastSuccess"));
      } else {
        await editEmployee({
          ...formData,
          id: id,
          workStartedDate: formattedDate,
          birthDate: birthFormattedDate,
          assPlayerId:
            formData.assPlayerId === "" ? null : formData.assPlayerId,
        });
        toast.success(`${t("EmployeeAdd.toastEdited")} ${missingFields}`);
      }
      setFormData({
        profileUrl: "",
        firstName: "",
        lastName: "",
        workStartedDate: "",
        birthDate: "",
        departmentId: "",
        teamId: "",
        albaId: "",
        highOfficial: "",
        workAddress: "",
        email: "",
        position: "",
        type: "",
        parentId: "",
        assPlayerId: "",
      });
      navigate("/company/employee");
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <>
      <div className="relative">
        <div className="m-auto w-10/12 pt-11">
          <div>
            <p className="text-[20px] font-bold text-[#293951]">
              {t("EmployeeAdd.title")}
            </p>
            <p className="mt-10 text-base">{t("EmployeeAdd.information")}</p>
            <form className="mt-10" id="AddWorker">
              <div className="flex items-center">
                {console.log(formData.profileUrl)}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                  ref={fileInputRef}
                />
                <div
                  className={`border relative rounded-full w-max ${
                    formData.profileUrl ? "p-0" : "p-2"
                  } `}
                  onClick={handleImageClick}
                >
                  {formData.profileUrl !== "" ? (
                    <img
                      src={formData.profileUrl}
                      alt="selected user img"
                      className={`m-0 w-20 rounded-full h-20 text-[#666874]`}
                    />
                  ) : (
                    <BiSolidUser className="m-0  cursor-pointer w-16 h-16 text-[#666874]" />
                  )}
                  <div className="absolute right-[-10px] bottom-[-10px] p-1 border rounded-full bg-[#324D72] border-[#324D72]">
                    <FiEdit2 className="cursor-pointer w-4 h-4 m-[4px] text-[#fff]" />
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="ml-10">
                    <img
                      onClick={() =>
                        setFormData({ ...formData, profileUrl: "/user.svg" })
                      }
                      src={"/user.svg"}
                      className={`cursor-pointer ${
                        formData.profileUrl === "/user.svg"
                          ? "border border-[#3D608C] rounded-full"
                          : ""
                      }`}
                      alt="user img"
                    />
                  </div>
                  <div>
                    <img
                      onClick={() =>
                        setFormData({ ...formData, profileUrl: "/user1.svg" })
                      }
                      src={"/user1.svg"}
                      className={`cursor-pointer ${
                        formData.profileUrl === "/user1.svg"
                          ? "border border-[#3D608C] rounded-full"
                          : ""
                      }`}
                      alt="user img"
                    />
                  </div>
                  <div>
                    <img
                      onClick={() =>
                        setFormData({ ...formData, profileUrl: "/user2.svg" })
                      }
                      src={"/user2.svg"}
                      className={`cursor-pointer ${
                        formData.profileUrl === "/user2.svg"
                          ? "border border-[#3D608C] rounded-full"
                          : ""
                      }`}
                      alt="user img"
                    />
                  </div>
                  <div>
                    <img
                      onClick={() =>
                        setFormData({ ...formData, profileUrl: "/user3.svg" })
                      }
                      src={"/user3.svg"}
                      className={`cursor-pointer ${
                        formData.profileUrl === "/user3.svg"
                          ? "border border-[#3D608C] rounded-full"
                          : ""
                      }`}
                      alt="user img"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <div className="flex gap-20">
                  <div className="mb-6 flex-grow">
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("EmployeeAdd.lastname")}
                    </label>
                    <MainInput
                      type="text"
                      id="lastName"
                      className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                      value={formData.lastName}
                      onChange={(e) =>
                        setFormData({ ...formData, lastName: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-6  flex-grow">
                    <label
                      htmlFor="firstname"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("EmployeeAdd.firstname")}
                    </label>
                    <div className="formAutoComplete48">
                      <Autocomplete
                        className="rounded-lg w-full"
                        value={formData.firstName}
                        onChange={(e, newValue) => {
                          if (newValue?.apId) {
                            setFormData((prevData) => ({
                              ...prevData,
                              assPlayerId: newValue.apId,
                              firstName: newValue.name,
                              email: newValue.email,
                            }));
                          } else {
                            setFormData((prevData) => ({
                              ...prevData,
                              assPlayerId: "",
                              firstName: "",
                              email: "",
                            }));
                          }
                        }}
                        onInputChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            assPlayerId:
                              prevData.firstName !== newValue
                                ? ""
                                : prevData.assPlayerId,
                            firstName: newValue,
                            email:
                              prevData.firstName !== newValue
                                ? ""
                                : prevData.email,
                          }));
                        }}
                        selectOnFocus
                        clearOnBlur
                        id="player"
                        options={players}
                        noOptionsText={null} // Set to null to disable the "no options" message
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return (
                            `${option.name.toString()} ${option.email}` || ""
                          );
                        }}
                        renderOption={(props, option) => {
                          props = {
                            ...props,
                            key: `${option.apId}_${option.name}_${option.pId}`,
                          };
                          return (
                            <li key={option.pId} {...props}>
                              {" "}
                              {option.name} {option.email}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="birthDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.birthdate")}
                  </label>
                  <div>
                    <MainInput
                      type="date"
                      id="birthDate"
                      className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 "
                      value={formData.birthDate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          birthDate: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="workStartedDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.workStartDate")}
                  </label>
                  <div>
                    <MainInput
                      type="date"
                      id="workStartedDate"
                      className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 "
                      value={formData.workStartedDate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          workStartedDate: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="departmentId"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.department")}
                  </label>
                  <div className="formAutoComplete">
                    <Autocomplete
                      className="rounded-lg w-full"
                      value={selectedDepat}
                      onChange={(e, newValue) => {
                        if (newValue?.id) {
                          setFormData((prevData) => ({
                            ...prevData,
                            departmentId: newValue.id,
                            departmentName: null,
                          }));
                        } else {
                          setFormData((prevData) => ({
                            ...prevData,
                            departmentId: null,
                            departmentName: null,
                          }));
                        }
                      }}
                      onInputChange={(event, newValue) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          departmentId: null,
                          departmentName: newValue,
                        }));
                        setSelectedDepat(newValue);
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="department"
                      options={departmentOptions}
                      noOptionsText={null} // Set to null to disable the "no options" message
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name || "";
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </div>
                </div>
                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="albaId"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {"Алба"}
                  </label>
                  <div className="formAutoComplete">
                    <Autocomplete
                      className="rounded-lg w-full"
                      value={selectedAlba}
                      onChange={(e, newValue) => {
                        if (newValue?.id) {
                          setFormData((prevData) => ({
                            ...prevData,
                            albaId: newValue.id,
                            albaName: null,
                          }));
                        } else {
                          setFormData((prevData) => ({
                            ...prevData,
                            albaId: null,
                            albaName: null,
                          }));
                        }
                      }}
                      onInputChange={(event, newValue) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          albaId: null,
                          albaName: newValue,
                        }));
                        setSelectedAlba(newValue);
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="alba"
                      options={albaOptions}
                      noOptionsText={null} // Set to null to disable the "no options" message
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name || "";
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </div>
                </div>
                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="teamId"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.team")}
                  </label>
                  <div className="formAutoComplete">
                    <Autocomplete
                      className="rounded-lg w-full"
                      value={selectedTeam}
                      onChange={(e, newValue) => {
                        if (newValue?.id) {
                          setFormData((prevData) => ({
                            ...prevData,
                            teamId: newValue.id,
                            teamName: null,
                          }));
                        } else {
                          setFormData((prevData) => ({
                            ...prevData,
                            teamId: null,
                            teamName: null,
                          }));
                        }
                      }}
                      onInputChange={(event, newValue) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          teamId: null,
                          teamName: newValue,
                        }));
                        setSelectedTeam(newValue);
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="team"
                      options={teamOptions}
                      noOptionsText={null} // Set to null to disable the "no options" message
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name || "";
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </div>
                </div>
                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="highOfficial"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.highOfficial")}
                  </label>

                  <div className="relative">
                    <select
                      id="highOfficial"
                      className="w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                      required
                      value={formData.parentId}
                      onChange={(e) => {
                        if (highOfficialName.length === 0) {
                          setFormData({
                            ...formData,
                            highOfficialId: null,
                            highOfficial: null,
                          });
                        } else {
                          const selectedValue = e.target.value;
                          const selectedHighOfficial = highOfficialName.find(
                            (data) => data.id === selectedValue
                          );
                          setFormData({
                            ...formData,
                            parentId: selectedValue,
                            highOfficial: selectedHighOfficial
                              ? `${selectedHighOfficial.firstName} ${selectedHighOfficial.lastName}`
                              : "",
                          });
                        }
                      }}
                    >
                      <option value="" disabled hidden>
                        {t("EmployeeAdd.selectChoose")}
                      </option>
                      {highOfficialName.map((data) => (
                        <option key={data.id} value={data.id}>
                          {`${data.firstName} ${data.lastName}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="department"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.workAddress")}
                  </label>
                  <MainInput
                    type="text"
                    id="workAddress"
                    className="pl-8 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    value={formData.workAddress}
                    onChange={(e) =>
                      setFormData({ ...formData, workAddress: e.target.value })
                    }
                  />
                </div>
                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="highOfficial"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.email")}
                  </label>
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                      <AiOutlineSearch className="text-[#666874]" />
                    </span>
                    <MainInput
                      type="email"
                      id="email"
                      placeholder="example@gmail.com"
                      className="pl-8 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                      value={formData.email}
                      onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <h1 className="mb-6">
                  {t("EmployeeAdd.MoreInformationTitle")}
                </h1>

                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="highOfficial"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.Position")}
                  </label>
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                      <AiOutlineSearch className="text-[#666874]" />
                    </span>
                    <MainInput
                      type="text"
                      id="position"
                      placeholder=""
                      className="pl-8 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                      value={formData.position}
                      onChange={(e) =>
                        setFormData({ ...formData, position: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="mb-6 flex-grow">
                  <label
                    htmlFor="department"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("EmployeeAdd.employeeType")}
                  </label>
                  <div className="relative">
                    <select
                      id="workAddress"
                      className="w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                      value={formData.type}
                      onChange={(e) =>
                        setFormData({ ...formData, type: e.target.value })
                      }
                    >
                      <option value="" disabled hidden>
                        {t("EmployeeAdd.selectChoose")}
                      </option>
                      <option value="OFFICIAL">
                        {t("EmployeeAdd.selectOfficial")}
                      </option>
                      <option value="CONTRACT">
                        {t("EmployeeAdd.selectContract")}
                      </option>
                      <option value="NEW">{t("EmployeeAdd.selectNew")}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="sticky bg-white bottom-0">
          <hr className="flex-grow mb-10 mt-20 " />
          <div className=" flex justify-end mb-5 mr-20 items-center">
            <div className="mb-5">
              <a
                className="border hover:bg-[#f0f0f0] mr-5 focus:ring-4 focus:bg-[#f0f0f0] bg-white font-medium rounded-lg text-sm px-5 py-3 mr-2 mb-2"
                href="/company/employee"
              >
                {t("EmployeeAdd.backButton")}
              </a>
              <button
                type="submit"
                form="AddWorker"
                onClick={handleFormSubmit}
                className="text-white bg-[#324D72] hover:bg-[#324d60] px-20 focus:ring-4 focus:bg-[#324d60] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
              >
                {isEdit
                  ? t("EmployeeAdd.saveButton")
                  : t("EmployeeAdd.addButton")}
              </button>
            </div>
            <ToastContainer className="mt-20" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWorker;
