import React, { useEffect, useState } from "react";
import { IoSchoolOutline } from "react-icons/io5";
import { getB2cExp } from "../../../services/assessment.service";
import { getPlayerReport } from "../../../services/game.service";
import { useParams } from "react-router-dom";
import Loading from "../../../components/uncommon/Loading";
import { MdWorkOutline } from "react-icons/md";

const CV = ({ id, fileUrl }) => {
  const [b2cId, setB2cId] = useState(null);
  const [datas, setDatas] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPlayerReport(id)
      .then((data) => {
        setB2cId(data.data.assPlayer.player.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    if (b2cId) {
      getB2cExp(b2cId)
        .then((data) => {
          setDatas(data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [b2cId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {datas !== null ? (
        <div className="flex items-start gap-10 mb-10">
          <div>
            {datas?.education.map((items, index) => {
              return (
                <div
                  key={index}
                  className="shadow-xl rounded-xl px-4 py-6 w-[400px] mb-6"
                >
                  <p className="text-semibold text-lg">Боловсрол</p>
                  <hr className="text-[#E5E5E5] mt-2 mb-4" />
                  <div className="flex items-center gap-4">
                    <div className="p-3 bg-[#F3F5F5] rounded-full">
                      <IoSchoolOutline className="h-8 w-8" />
                    </div>
                    <div>
                      <p className="text-semibold">{items.major}</p>
                      <p className="text-sm my-2">
                        {items.schoolName}- {items.country}
                      </p>
                      <p className="text-xs text-[#939393] ">
                        {datas?.experience == [] ? "Сурж байгаа" : "Төгссөн"}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            {datas?.experience.map((items, index) => {
              return (
                <div
                  key={index}
                  className="shadow-xl rounded-xl px-4 py-6 w-[400px] mb-6"
                >
                  <p className="text-semibold text-lg">Туршлага</p>
                  <hr className="text-[#E5E5E5] mt-2 mb-4" />
                  <div className="flex items-center gap-4">
                    <div className="p-3 bg-[#F3F5F5] rounded-full">
                      <MdWorkOutline className="h-8 w-8" />
                    </div>
                    <div>
                      <p className="text-semibold">{items.position}</p>
                      <p className="text-sm my-2">{items.companyDirection}</p>
                      <p className="text-xs text-[#939393]">
                        {items.isWorking === null
                          ? "Одоо ажиллаж байгаа"
                          : "Гарсан"}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>Боловсрол болон Туршлага олдсонгүй</div>
      )}
      {fileUrl ? (
        <iframe title="File Viewer" width="100%" height="500px" src={fileUrl} />
      ) : (
        <p className="mt-5 mb-10">Үр дүн олдсонгүй</p>
      )}
    </div>
  );
};

export default CV;
