import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineChartPie } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import MainButton from "../components/buttons/MainButton";
import { useAuth } from "../context/AuthContext";
import { getUserInfo } from "../services/user.service";
import { IoIosArrowForward } from "react-icons/io";
import { getEmpData } from "../services/employee.service";

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const imgRef = useRef(null);
  const [open, setOpen] = React.useState(1);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [selectedMenu, setSelectedMenu] = useState(2);
  const { t, i18n } = useTranslation();
  const { user, hasRole } = useAuth();
  const [userData, setUserData] = useState(null);
  const location = useLocation();
  const [opening, setOpening] = useState(false);

  const [feedbackId, setFeedbackId] = useState(null);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  useEffect(() => {
    getUserInfo()
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getEmpData()
      .then((data) => {
        setFeedbackId(data.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/company/employee")) {
      setOpening(true);
    } else {
      setOpening(false);
    }
  }, [location]);

  useEffect(() => {
    if (userData && sidebarOpen) {
      const imgElement = imgRef.current;

      const handleImageLoad = () => {
        const imgWidth = imgElement.naturalWidth;
        const imgHeight = imgElement.naturalHeight;

        if (imgWidth > imgHeight) {
          if (imgWidth > 175) {
            setImgSize({ width: 175, height: "auto" });
          } else {
            if (imgHeight > 100) {
              setImgSize({ width: "auto", height: 100 });
            } else {
              setImgSize({ width: imgWidth, height: imgHeight });
            }
          }
        } else {
          if (imgHeight > 100) {
            setImgSize({ width: "auto", height: 100 });
          } else {
            setImgSize({ width: imgWidth, height: imgHeight });
          }
        }
      };

      imgElement.addEventListener("load", handleImageLoad);

      return () => {
        if (imgElement) {
          imgElement.removeEventListener("load", handleImageLoad);
        }
      };
    }
  }, [userData, sidebarOpen]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <Card
      className={`h-[calc(96vh)] max-w-[20rem] border-r border-[#CECFD3] flex flex-col justify-between transition-all duration-300 relative  ms-4 my-4 ${
        sidebarOpen ? " w-[270px] rounded-[30px]" : "w-[60px] rounded-[25px]"
      }`}
    >
      {opening && (
        <button
          onClick={toggleSidebar}
          className="p-1 border rounded-full absolute  bottom-10 -right-3 z-20 bg-white"
        >
          <IoIosArrowForward
            className={`text-lg transition-transform duration-300 ${
              sidebarOpen ? "rotate-180" : ""
            }`}
          />
        </button>
      )}

      {sidebarOpen ? (
        <div>
          <Link to={"/"} className=" flex justify-center gap-4 p-4 mt-4 ">
            {userData !== null ? (
              <img
                ref={imgRef}
                src={userData.companies[0].photoUrl}
                alt="logo"
                style={{ width: imgSize.width, height: imgSize.height }}
              />
            ) : (
              <img width={"175px"} src="./oneplacehrLogo.png " alt="logo" />
            )}
          </Link>
          <List className="px-4 pb-4">
            <div className="flex px-3 items-center">
              <HiOutlineChartPie className="h-5 w-5 text-[#2C4360]" />
              <ListItem className="ml-1 p-0" ripple={false}>
                <Link to="/control">
                  <MainButton
                    onClick={() => {
                      setSelectedMenu(1);
                    }}
                    labelColor={"text-[#2C4360]"}
                    customClass={"text-sm"}
                    isSelected={selectedMenu === 1 ? true : false}
                    label={t("Sidebar.dashboard")}
                    selectedColor="bg-[#EEF2FF]"
                    selectedTextColor="text-[#F48D79]"
                  />
                </Link>
              </ListItem>
            </div>
            <Accordion
              open={open === 1}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform text-[#2C4360] ${
                    open === 1 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 1}>
                <AccordionHeader
                  onClick={() => handleOpen(1)}
                  className="border-b-0 p-3"
                >
                  <ListItemPrefix>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3333 7.4987V5.83203H16.6667V7.4987H15V9.16536H16.6667V10.832H18.3333V9.16536H20V7.4987H18.3333Z"
                        fill="#2C4360"
                      />
                      <path
                        d="M6.66667 9.9987C8.50833 9.9987 10 8.50703 10 6.66536C10 4.8237 8.50833 3.33203 6.66667 3.33203C4.825 3.33203 3.33333 4.8237 3.33333 6.66536C3.33333 8.50703 4.825 9.9987 6.66667 9.9987ZM6.66667 4.9987C7.58333 4.9987 8.33333 5.7487 8.33333 6.66536C8.33333 7.58203 7.58333 8.33203 6.66667 8.33203C5.75 8.33203 5 7.58203 5 6.66536C5 5.7487 5.75 4.9987 6.66667 4.9987Z"
                        fill="#2C4360"
                      />
                      <path
                        d="M6.66667 10.832C4.44167 10.832 0 11.9487 0 14.1654V16.6654H13.3333V14.1654C13.3333 11.9487 8.89167 10.832 6.66667 10.832ZM11.6667 14.9987H1.66667V14.1737C1.83333 13.5737 4.41667 12.4987 6.66667 12.4987C8.91667 12.4987 11.5 13.5737 11.6667 14.1654V14.9987Z"
                        fill="#2C4360"
                      />
                      <path
                        d="M10.425 3.3737C11.1917 4.25703 11.6667 5.40703 11.6667 6.66536C11.6667 7.9237 11.1917 9.0737 10.425 9.95703C12.0583 9.7487 13.3333 8.36536 13.3333 6.66536C13.3333 4.96536 12.0583 3.58203 10.425 3.3737Z"
                        fill="#2C4360"
                      />
                      <path
                        d="M13.775 11.5237C14.5167 12.2154 15 13.082 15 14.1654V16.6654H16.6667V14.1654C16.6667 12.957 15.3417 12.0737 13.775 11.5237Z"
                        fill="#2C4360"
                      />
                    </svg>
                  </ListItemPrefix>
                  <Typography className="text-[#2C4360] mr-auto font-normal text-sm">
                    {t("Sidebar.selectionProcesss")}
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <div className="flex ml-5">
                  <div className="w-px bg-[#E5E6E8] flex-shrink-0 justify-right"></div>
                  <List className="p-0">
                    <ListItem className="ml-1 p-0" ripple={false}>
                      <Link to={"/"}>
                        <MainButton
                          onClick={() => {
                            setSelectedMenu(2);
                          }}
                          labelColor={"text-[#64748B]"}
                          customClass={"text-sm"}
                          isSelected={selectedMenu === 2 ? true : false}
                          label={t("Sidebar.assessments")}
                          selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                        />
                      </Link>
                    </ListItem>
                    <ListItem className="ml-1 p-0" ripple={false}>
                      <Link to={"/customer/assessments/total"}>
                        <MainButton
                          onClick={() => {
                            setSelectedMenu(3);
                          }}
                          labelColor={"text-[#64748B]"}
                          customClass={"text-sm"}
                          isSelected={selectedMenu === 3 ? true : false}
                          label={t("Sidebar.total")}
                          selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                        />
                      </Link>
                    </ListItem>
                  </List>
                </div>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={open === 2}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform text-[#2C4360] ${
                    open === 2 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 2}>
                <AccordionHeader
                  onClick={() => handleOpen(2)}
                  className="border-b-0 p-3"
                >
                  <ListItemPrefix>
                    <svg
                      width="20"
                      height="10"
                      viewBox="0 0 20 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.33333 5.83333C4.25 5.83333 5 5.08333 5 4.16667C5 3.25 4.25 2.5 3.33333 2.5C2.41667 2.5 1.66667 3.25 1.66667 4.16667C1.66667 5.08333 2.41667 5.83333 3.33333 5.83333ZM4.275 6.75C3.96667 6.7 3.65833 6.66667 3.33333 6.66667C2.50833 6.66667 1.725 6.84167 1.01667 7.15C0.4 7.41667 0 8.01667 0 8.69167V10H3.75V8.65833C3.75 7.96667 3.94167 7.31667 4.275 6.75ZM16.6667 5.83333C17.5833 5.83333 18.3333 5.08333 18.3333 4.16667C18.3333 3.25 17.5833 2.5 16.6667 2.5C15.75 2.5 15 3.25 15 4.16667C15 5.08333 15.75 5.83333 16.6667 5.83333ZM20 8.69167C20 8.01667 19.6 7.41667 18.9833 7.15C18.275 6.84167 17.4917 6.66667 16.6667 6.66667C16.3417 6.66667 16.0333 6.7 15.725 6.75C16.0583 7.31667 16.25 7.96667 16.25 8.65833V10H20V8.69167ZM13.5333 6.375C12.5583 5.94167 11.3583 5.625 10 5.625C8.64167 5.625 7.44167 5.95 6.46667 6.375C5.56667 6.775 5 7.675 5 8.65833V10H15V8.65833C15 7.675 14.4333 6.775 13.5333 6.375ZM6.725 8.33333C6.8 8.14167 6.83333 8.00833 7.48333 7.75833C8.29167 7.44167 9.14167 7.29167 10 7.29167C10.8583 7.29167 11.7083 7.44167 12.5167 7.75833C13.1583 8.00833 13.1917 8.14167 13.275 8.33333H6.725ZM10 1.66667C10.4583 1.66667 10.8333 2.04167 10.8333 2.5C10.8333 2.95833 10.4583 3.33333 10 3.33333C9.54167 3.33333 9.16667 2.95833 9.16667 2.5C9.16667 2.04167 9.54167 1.66667 10 1.66667ZM10 0C8.61667 0 7.5 1.11667 7.5 2.5C7.5 3.88333 8.61667 5 10 5C11.3833 5 12.5 3.88333 12.5 2.5C12.5 1.11667 11.3833 0 10 0Z"
                        fill="#2C4360"
                      />
                    </svg>
                  </ListItemPrefix>
                  <Typography className="text-[#2C4360] mr-auto font-normal text-sm">
                    {t("Sidebar.myCompany")}
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <div className="flex ml-5">
                  <div className="w-px bg-[#E5E6E8] flex-shrink-0 justify-right"></div>
                  <List className="p-0">
                    <ListItem className="ml-1 p-0" ripple={false}>
                      <Link to={"/company/employee"}>
                        <MainButton
                          onClick={() => {
                            setSelectedMenu(4);
                          }}
                          labelColor={"text-[#64748B]"}
                          customClass={"text-sm"}
                          isSelected={selectedMenu === 4 ? true : false}
                          label={t("Sidebar.employeeRegister")}
                          selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                        />
                      </Link>
                    </ListItem>
                    <ListItem className="ml-1 p-0" ripple={false}>
                      <Link to={"/company/analytic"}>
                        <MainButton
                          onClick={() => {
                            setSelectedMenu(5);
                          }}
                          labelColor={"text-[#64748B]"}
                          customClass={"text-sm"}
                          isSelected={selectedMenu === 5 ? true : false}
                          label={t("Sidebar.analytics")}
                          selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                        />
                      </Link>
                    </ListItem>
                  </List>
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 3}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform text-[#2C4360] ${
                    open === 3 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 3}>
                <AccordionHeader
                  onClick={() => handleOpen(3)}
                  className="border-b-0 p-3"
                >
                  <ListItemPrefix>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.5 14.8333V9.83333C6.5 8.91286 5.75381 8.16667 4.83333 8.16667H3.16667C2.24619 8.16667 1.5 8.91286 1.5 9.83333V14.8333C1.5 15.7538 2.24619 16.5 3.16667 16.5H4.83333C5.75381 16.5 6.5 15.7538 6.5 14.8333ZM6.5 14.8333V6.5C6.5 5.57953 7.24619 4.83333 8.16667 4.83333H9.83333C10.7538 4.83333 11.5 5.57953 11.5 6.5V14.8333M6.5 14.8333C6.5 15.7538 7.24619 16.5 8.16667 16.5H9.83333C10.7538 16.5 11.5 15.7538 11.5 14.8333M11.5 14.8333V3.16667C11.5 2.24619 12.2462 1.5 13.1667 1.5H14.8333C15.7538 1.5 16.5 2.24619 16.5 3.16667V14.8333C16.5 15.7538 15.7538 16.5 14.8333 16.5H13.1667C12.2462 16.5 11.5 15.7538 11.5 14.8333Z"
                        stroke="#2C4360"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemPrefix>
                  <Typography className="text-[#2C4360] mr-auto font-normal text-sm">
                    {t("Sidebar.dataCollection")}
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <div className="flex ml-5">
                  <div className="w-px bg-[#E5E6E8] flex-shrink-0 justify-right"></div>
                  <List className="p-0">
                    <ListItem className="ml-1 p-0" ripple={false}>
                      <Link to={"/company/burnout/report"}>
                        <MainButton
                          onClick={() => {
                            setSelectedMenu(6);
                          }}
                          labelColor={"text-[#64748B]"}
                          customClass={"text-sm"}
                          isSelected={selectedMenu === 6 ? true : false}
                          label={t("Sidebar.burnout")}
                          selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                        />
                      </Link>
                    </ListItem>
                    {/* <ListItem className="ml-1 p-0" ripple={false}>
                    <Link to={"/company/softSkill"}>
                      <MainButton
                        onClick={() => {
                          setSelectedMenu(7);
                        }}
                        labelColor={"text-[#64748B]"}
                        customClass={"text-sm"}
                        isSelected={selectedMenu === 7 ? true : false}
                        label={t("Sidebar.softSkill")}
                        selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                      />
                    </Link>
                  </ListItem> */}
                    {/* <ListItem className="ml-1 p-0" ripple={false}>
                    <Link to={"/company/test"}>
                      <MainButton
                        onClick={() => {
                          setSelectedMenu(8);
                        }}
                        labelColor={"text-[#64748B]"}
                        customClass={"text-sm"}
                        isSelected={selectedMenu === 8 ? true : false}
                        label={t("Sidebar.softSkill")}
                        selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                      />
                    </Link>
                  </ListItem> */}

                    <ListItem className="ml-1 p-0" ripple={false}>
                      <Link to={"/company/ona"}>
                        <MainButton
                          onClick={() => {
                            setSelectedMenu(9);
                          }}
                          labelColor={"text-[#64748B]"}
                          customClass={"text-sm"}
                          isSelected={selectedMenu === 9 ? true : false}
                          label={t("Sidebar.onaSurvey")}
                          selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                        />
                      </Link>
                    </ListItem>

                    <ListItem className="ml-1 p-0" ripple={false}>
                      <Link to="/company/onBoarding">
                        <MainButton
                          onClick={() => {
                            setSelectedMenu(10);
                          }}
                          labelColor={"text-[#64748B]"}
                          customClass={"text-sm"}
                          isSelected={selectedMenu === 10 ? true : false}
                          label={t("Sidebar.adaptation")}
                          selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                        />
                      </Link>
                    </ListItem>
                    <ListItem className="ml-1 p-0" ripple={false}>
                      <Link to={"/company/newSurvey"}>
                        <MainButton
                          onClick={() => {
                            setSelectedMenu(11);
                          }}
                          labelColor={"text-[#64748B]"}
                          customClass={"text-sm"}
                          isSelected={selectedMenu === 11 ? true : false}
                          label={t("Sidebar.survey")}
                          selectedColor="bg-[#EEF2FF]"
                          selectedTextColor="text-[#F48D79]"
                        />
                      </Link>
                    </ListItem>
                    {userData?.role === "ADMIN" ||
                    userData?.role === "MANAGER" ? (
                      <ListItem className="ml-1 p-0" ripple={false}>
                        <Link to={`/company/employee/${feedbackId}/report`}>
                          <MainButton
                            onClick={() => {
                              setSelectedMenu(12);
                            }}
                            labelColor={"text-[#64748B] "}
                            customClass={"text-sm"}
                            isSelected={selectedMenu === 12 ? true : false}
                            label={
                              userData?.companies[0].id === 31
                                ? t("Sidebar.feedback")
                                : t("Sidebar.feedback2")
                            }
                            selectedColor="bg-[#EEF2FF] "
                            selectedTextColor="text-[#F48D79]"
                          />
                        </Link>
                      </ListItem>
                    ) : (
                      ""
                    )}
                  </List>
                </div>
              </AccordionBody>
            </Accordion>
            {/* <div className="flex px-3 items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.16667 1.5V4.83333M1.5 3.16667H4.83333M4 13.1667V16.5M2.33333 14.8333H5.66667M9.83333 1.5L11.7381 7.21429L16.5 9L11.7381 10.7857L9.83333 16.5L7.92857 10.7857L3.16667 9L7.92857 7.21429L9.83333 1.5Z"
                  stroke="#2C4360"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex ms-2">
                <ListItem className="ml-1 p-0" ripple={false}>
                  <Link to="/assisstant">
                    <MainButton
                      onClick={() => {
                        setSelectedMenu(13);
                      }}
                      labelColor={"text-[#2C4360]"}
                      customClass={"text-sm"}
                      isSelected={selectedMenu === 13 ? true : false}
                      label={t("Sidebar.aiAdvice")}
                    />
                  </Link>
                </ListItem>
              </div>
            </div> */}
          </List>
        </div>
      ) : (
        <div className="mt-16">
          <Link
            onClick={() => {
              setSelectedMenu(1);
            }}
            className={`flex justify-center py-3 ${
              selectedMenu === 1
                ? "bg-[#EEF2FF] text-[#F48D79]"
                : "text-[#2C4360]"
            }`}
            to="/control"
          >
            <HiOutlineChartPie className="h-[26px] w-[26px] " />
          </Link>
          <Link
            onClick={() => {
              setSelectedMenu(2);
            }}
            className={`flex justify-center py-3 ${
              selectedMenu === 2
                ? "bg-[#EEF2FF] text-[#F48D79]"
                : "text-[#2C4360]"
            }`}
            to="/"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3333 7.4987V5.83203H16.6667V7.4987H15V9.16536H16.6667V10.832H18.3333V9.16536H20V7.4987H18.3333Z"
                fill={`${selectedMenu === 2 ? "#F48D79" : "#2C4360"}`}
              />
              <path
                d="M6.66667 9.9987C8.50833 9.9987 10 8.50703 10 6.66536C10 4.8237 8.50833 3.33203 6.66667 3.33203C4.825 3.33203 3.33333 4.8237 3.33333 6.66536C3.33333 8.50703 4.825 9.9987 6.66667 9.9987ZM6.66667 4.9987C7.58333 4.9987 8.33333 5.7487 8.33333 6.66536C8.33333 7.58203 7.58333 8.33203 6.66667 8.33203C5.75 8.33203 5 7.58203 5 6.66536C5 5.7487 5.75 4.9987 6.66667 4.9987Z"
                fill={`${selectedMenu === 2 ? "#F48D79" : "#2C4360"}`}
              />
              <path
                d="M6.66667 10.832C4.44167 10.832 0 11.9487 0 14.1654V16.6654H13.3333V14.1654C13.3333 11.9487 8.89167 10.832 6.66667 10.832ZM11.6667 14.9987H1.66667V14.1737C1.83333 13.5737 4.41667 12.4987 6.66667 12.4987C8.91667 12.4987 11.5 13.5737 11.6667 14.1654V14.9987Z"
                fill={`${selectedMenu === 2 ? "#F48D79" : "#2C4360"}`}
              />
              <path
                d="M10.425 3.3737C11.1917 4.25703 11.6667 5.40703 11.6667 6.66536C11.6667 7.9237 11.1917 9.0737 10.425 9.95703C12.0583 9.7487 13.3333 8.36536 13.3333 6.66536C13.3333 4.96536 12.0583 3.58203 10.425 3.3737Z"
                fill={`${selectedMenu === 2 ? "#F48D79" : "#2C4360"}`}
              />
              <path
                d="M13.775 11.5237C14.5167 12.2154 15 13.082 15 14.1654V16.6654H16.6667V14.1654C16.6667 12.957 15.3417 12.0737 13.775 11.5237Z"
                fill={`${selectedMenu === 2 ? "#F48D79" : "#2C4360"}`}
              />
            </svg>
          </Link>

          <Link
            onClick={() => {
              setSelectedMenu(4);
            }}
            className={`flex justify-center py-3 ${
              selectedMenu === 4
                ? "bg-[#EEF2FF] text-[#F48D79]"
                : "text-[#2C4360]"
            }`}
            to="/company/employee"
          >
            <svg
              width="24"
              height="14"
              viewBox="0 0 20 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33333 5.83333C4.25 5.83333 5 5.08333 5 4.16667C5 3.25 4.25 2.5 3.33333 2.5C2.41667 2.5 1.66667 3.25 1.66667 4.16667C1.66667 5.08333 2.41667 5.83333 3.33333 5.83333ZM4.275 6.75C3.96667 6.7 3.65833 6.66667 3.33333 6.66667C2.50833 6.66667 1.725 6.84167 1.01667 7.15C0.4 7.41667 0 8.01667 0 8.69167V10H3.75V8.65833C3.75 7.96667 3.94167 7.31667 4.275 6.75ZM16.6667 5.83333C17.5833 5.83333 18.3333 5.08333 18.3333 4.16667C18.3333 3.25 17.5833 2.5 16.6667 2.5C15.75 2.5 15 3.25 15 4.16667C15 5.08333 15.75 5.83333 16.6667 5.83333ZM20 8.69167C20 8.01667 19.6 7.41667 18.9833 7.15C18.275 6.84167 17.4917 6.66667 16.6667 6.66667C16.3417 6.66667 16.0333 6.7 15.725 6.75C16.0583 7.31667 16.25 7.96667 16.25 8.65833V10H20V8.69167ZM13.5333 6.375C12.5583 5.94167 11.3583 5.625 10 5.625C8.64167 5.625 7.44167 5.95 6.46667 6.375C5.56667 6.775 5 7.675 5 8.65833V10H15V8.65833C15 7.675 14.4333 6.775 13.5333 6.375ZM6.725 8.33333C6.8 8.14167 6.83333 8.00833 7.48333 7.75833C8.29167 7.44167 9.14167 7.29167 10 7.29167C10.8583 7.29167 11.7083 7.44167 12.5167 7.75833C13.1583 8.00833 13.1917 8.14167 13.275 8.33333H6.725ZM10 1.66667C10.4583 1.66667 10.8333 2.04167 10.8333 2.5C10.8333 2.95833 10.4583 3.33333 10 3.33333C9.54167 3.33333 9.16667 2.95833 9.16667 2.5C9.16667 2.04167 9.54167 1.66667 10 1.66667ZM10 0C8.61667 0 7.5 1.11667 7.5 2.5C7.5 3.88333 8.61667 5 10 5C11.3833 5 12.5 3.88333 12.5 2.5C12.5 1.11667 11.3833 0 10 0Z"
                fill={`${selectedMenu === 4 ? "#F48D79" : "#2C4360"}`}
              />
            </svg>
          </Link>
          <Link
            onClick={() => {
              setSelectedMenu(5);
            }}
            className={`flex justify-center py-3 ${
              selectedMenu === 5
                ? "bg-[#EEF2FF] text-[#F48D79]"
                : "text-[#2C4360]"
            }`}
            to="/company/burnout/report"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 14.8333V9.83333C6.5 8.91286 5.75381 8.16667 4.83333 8.16667H3.16667C2.24619 8.16667 1.5 8.91286 1.5 9.83333V14.8333C1.5 15.7538 2.24619 16.5 3.16667 16.5H4.83333C5.75381 16.5 6.5 15.7538 6.5 14.8333ZM6.5 14.8333V6.5C6.5 5.57953 7.24619 4.83333 8.16667 4.83333H9.83333C10.7538 4.83333 11.5 5.57953 11.5 6.5V14.8333M6.5 14.8333C6.5 15.7538 7.24619 16.5 8.16667 16.5H9.83333C10.7538 16.5 11.5 15.7538 11.5 14.8333M11.5 14.8333V3.16667C11.5 2.24619 12.2462 1.5 13.1667 1.5H14.8333C15.7538 1.5 16.5 2.24619 16.5 3.16667V14.8333C16.5 15.7538 15.7538 16.5 14.8333 16.5H13.1667C12.2462 16.5 11.5 15.7538 11.5 14.8333Z"
                stroke={`${selectedMenu === 5 ? "#F48D79" : "#2C4360"}`}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          {/* <Link
            onClick={() => {
              setSelectedMenu(6);
            }}
            className={`flex justify-center py-3 ${
              selectedMenu === 6
                ? "bg-[#EEF2FF] text-[#F48D79]"
                : "text-[#2C4360]"
            }`}
            to="/assisstant"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.16667 1.5V4.83333M1.5 3.16667H4.83333M4 13.1667V16.5M2.33333 14.8333H5.66667M9.83333 1.5L11.7381 7.21429L16.5 9L11.7381 10.7857L9.83333 16.5L7.92857 10.7857L3.16667 9L7.92857 7.21429L9.83333 1.5Z"
                stroke={`${selectedMenu === 6 ? "#F48D79" : "#2C4360"}`}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link> */}
        </div>
      )}
      <div>
        {/* <div className="px-4 py-3">
          <div className="mb-4 px-4 py-3 border border-[#EFF0F6] rounded-xl">
            <p className="text-sm">
              Premium багц <br /> дуусахад
            </p>
            <p className="text-[#707070] text-[10px] my-2">
              <span className="text-[#000] text-2xl font-bold">27</span>
              <span className="text-lg">/60</span> өдөр үлджээ
            </p>
            <Link
              className="text-[#0E2E5B] underline flex items-center gap-2 justify-end"
              to="/subscription"
            >
              Багц сунгах <FaChevronRight />
            </Link>
          </div>
          <div className="flex px-3 items-center">
            <FiSettings className="text-[#64748B]" />

            <div className="flex ms-2">
              <ListItem className="ml-1 p-0" ripple={false}>
                <Link to="/assisstant">
                  <MainButton
                    onClick={() => {
                      setSelectedMenu(8);
                    }}
                    labelColor={"text-[#64748B]"}
                    customClass={"text-sm"}
                    isSelected={selectedMenu === 8 ? true : false}
                    label="Тохируулга"
                  />
                </Link>
              </ListItem>
            </div>
          </div>
          <div className="flex px-3 items-center">
            <HiOutlineInformationCircle className="text-[#64748B]" />

            <div className="flex ms-2">
              <ListItem className="ml-1 p-0" ripple={false}>
                <Link to="/assisstant">
                  <MainButton
                    onClick={() => {
                      setSelectedMenu(8);
                    }}
                    labelColor={"text-[#64748B]"}
                    customClass={"text-sm"}
                    isSelected={selectedMenu === 8 ? true : false}
                    label="Тусламж"
                  />
                </Link>
              </ListItem>
            </div>
          </div>
        </div> */}
        {sidebarOpen ? (
          <div>
            <hr className="h-3" />
            <List>
              <div
                className={`flex items-center gap-2 justify-center ${
                  sidebarOpen ? "px-4 py-3 " : ""
                }`}
              >
                <img
                  src={userData?.photoUrl}
                  alt={`${userData?.name} ${userData?.lastName}`}
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <p className="text-[#64748B] text-xs">Welcome back 👋</p>
                  <p className="text-sm">
                    {userData?.lastName} {userData?.name}
                  </p>
                </div>
              </div>
            </List>
          </div>
        ) : (
          <div className="absolute bottom-5 inset-x-0">
            <hr className="h-3" />
            <div className="flex items-center justify-center">
              <img
                src={userData?.photoUrl}
                alt={`${userData?.name} ${userData?.lastName}`}
                className="h-10 w-10 rounded-full"
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
