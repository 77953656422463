import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { LuCalendarDays } from "react-icons/lu";
import { MdOutlineGroup } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { Textarea } from "@material-tailwind/react";
import { getCompanyOfficialEmployee } from "../../services/employee.service";
import Loading from "../../components/uncommon/Loading";

const Analystic = () => {
  const [height3, setHeight3] = useState(window.innerHeight - 100);
  const { t } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [clicked, setClicked] = useState(0);
  const [hover, setHover] = useState(0);
  const modalRef = useRef(null);
  const coming = true;
  const [datas, setDatas] = useState(null);
  const [barData, setBarData] = useState({ first: 0, second: 0, third: 0 });
  const [potentialBarData, setPotentialBarData] = useState({
    first: 0,
    second: 0,
    third: 0,
  });

  useEffect(() => {
    getCompanyOfficialEmployee().then((data) => {
      const updatedData = data.data.map((employee) => ({
        ...employee,
        scores: employee.scores || {
          first: false,
          second: false,
          third: false,
        },
        potentialScore: employee.potentialScore || {
          first: false,
          second: false,
          third: false,
        },
      }));
      setDatas(updatedData);
    });
  }, []);

  const maxBarsPerColumn = 12;

  const handleButtonClick = (index, scoreType) => {
    const updatedDatas = datas?.map((data, dataIndex) => {
      if (dataIndex === index) {
        // Ensure `scores` is defined
        const scores = data.scores || {
          first: false,
          second: false,
          third: false,
        };

        // Toggle the selected score and set others to false
        const updatedScores = {
          first: scoreType === "first" ? !scores.first : false,
          second: scoreType === "second" ? !scores.second : false,
          third: scoreType === "third" ? !scores.third : false,
        };

        // Calculate changes for the bar chart
        const changes = {
          first: updatedScores.first ? 1 : scores.first ? -1 : 0,
          second: updatedScores.second ? 1 : scores.second ? -1 : 0,
          third: updatedScores.third ? 1 : scores.third ? -1 : 0,
        };

        // Update the bar chart data
        setBarData((prevBarData) => ({
          first: prevBarData.first + changes.first,
          second: prevBarData.second + changes.second,
          third: prevBarData.third + changes.third,
        }));

        return {
          ...data,
          scores: updatedScores,
        };
      }
      return data;
    });

    setDatas(updatedDatas);
  };

  const generateColumns = (count, color) => {
    const columns = [];
    while (count > 0) {
      const bars = [];
      for (let i = 0; i < Math.min(count, maxBarsPerColumn); i++) {
        bars.push(
          <div key={i} className={`w-[30px] ${color} h-[10px] mb-0.5`}></div>
        );
      }
      columns.push(
        <div key={columns.length} className={`flex flex-col items-center ml-1`}>
          {bars}
        </div>
      );
      count -= maxBarsPerColumn;
    }
    return columns;
  };

  const handlePotentialButtonClick = (index, scoreType) => {
    const updatedDatas = datas?.map((data, dataIndex) => {
      if (dataIndex === index) {
        // Ensure `scores` is defined
        const scores = data.potentialScore || {
          first: false,
          second: false,
          third: false,
        };

        // Toggle the selected score and set others to false
        const updatedScores = {
          first: scoreType === "first" ? !scores.first : false,
          second: scoreType === "second" ? !scores.second : false,
          third: scoreType === "third" ? !scores.third : false,
        };

        // Calculate changes for the bar chart
        const changes = {
          first: updatedScores.first ? 1 : scores.first ? -1 : 0,
          second: updatedScores.second ? 1 : scores.second ? -1 : 0,
          third: updatedScores.third ? 1 : scores.third ? -1 : 0,
        };

        // Update the bar chart data
        setPotentialBarData((prevBarData) => ({
          first: prevBarData.first + changes.first,
          second: prevBarData.second + changes.second,
          third: prevBarData.third + changes.third,
        }));

        return {
          ...data,
          potentialScore: updatedScores,
        };
      }
      return data;
    });

    setDatas(updatedDatas);
  };
  const generatePotentialColumns = (count, color) => {
    const columns = [];
    while (count > 0) {
      const bars = [];
      for (let i = 0; i < Math.min(count, maxBarsPerColumn); i++) {
        bars.push(
          <div key={i} className={`w-[30px] ${color} h-[10px] mb-0.5`}></div>
        );
      }
      columns.push(
        <div key={columns.length} className={`flex flex-col items-center ml-1`}>
          {bars}
        </div>
      );
      count -= maxBarsPerColumn;
    }
    return columns;
  };

  const toggleCalendar = () => setShowCalendar(!showCalendar);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePresetDate = (preset) => {
    let date;
    switch (preset) {
      case "Өнөөдөр":
        date = new Date();
        break;
      case "Өнгөрсөн 7 хоног":
        date = new Date();
        date.setDate(date.getDate() - 7);
        break;
      case "Өнгөрсөн сар":
        date = new Date();
        date.setMonth(date.getMonth() - 1);
        break;
      case "Өнгөрсөн жил":
        date = new Date();
        date.setFullYear(date.getFullYear() - 1);
        break;
      default:
        date = null;
    }
    setSelectedDate(date);
  };
  const renderContent = () => {
    switch (clicked) {
      case 1:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.first && e.potentialScore.third)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    {console.log(e, "1")}
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      case 2:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.second && e.potentialScore.third)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      case 3:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.third && e.potentialScore.third)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      case 4:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.first && e.potentialScore.second)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      case 5:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.second && e.potentialScore.second)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      case 6:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.third && e.potentialScore.second)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      case 7:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.first && e.potentialScore.first)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      case 8:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.second && e.potentialScore.first)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      case 9:
        return (
          <div>
            {datas
              ?.filter((e) => e.scores.third && e.potentialScore.first)
              .map((e, index) => {
                return (
                  <div key={index} className="flex items-center gap-4 mb-3">
                    <img
                      className="border rounded-full"
                      src="/analytics/pfp.svg"
                      alt="pfp"
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {e.lastName}, {e.firstName}
                    </p>{" "}
                  </div>
                );
              })}
          </div>
        );
      default:
        return null;
    }
  };
  const renderTitle = () => {
    switch (clicked) {
      case 1:
        return (
          <div className="px-6 py-4 bg-[#FFE070] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box1")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")}</p>
          </div>
        );
      case 2:
        return (
          <div className="px-6 py-4 bg-[#7EEFB2] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box2")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")} </p>
          </div>
        );
      case 3:
        return (
          <div className="px-6 py-4 bg-[#00D97D] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box3")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")} </p>
          </div>
        );
      case 4:
        return (
          <div className="px-6 py-4 bg-[#FFBD70] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box4")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")} </p>
          </div>
        );
      case 5:
        return (
          <div className="px-6 py-4 bg-[#FFE070] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box5")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")} </p>
          </div>
        );
      case 6:
        return (
          <div className="px-6 py-4 bg-[#7EEFB2] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box6")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")} </p>
          </div>
        );
      case 7:
        return (
          <div className="px-6 py-4 bg-[#FF5F6E] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box7")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")} </p>
          </div>
        );
      case 8:
        return (
          <div className="px-6 py-4 bg-[#FFBD70] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box8")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")} </p>
          </div>
        );
      case 9:
        return (
          <div className="px-6 py-4 bg-[#FFE070] h-[25%] rounded-t-xl">
            <ul className="list-disc text-white ms-4">
              <li>{t("analytic.box9")}</li>
            </ul>
            <p className="text-[11px] text-white">{t("analytic.boxDesc3")} </p>
          </div>
        );
      default:
        return null;
    }
  };

  if (!coming) {
    return (
      <div className="relative" style={{ height: `${height3}px` }}>
        <div className="ms-10 mt-10">
          <p className="text-[#2C4360] font-semibold text-[36px] ">
            {t("comingSoon.title")}
          </p>
        </div>
        <img
          className="absolute right-0 bottom-0"
          src="/comingSoonImg/Analytic.svg"
          alt="coming soon"
        />
      </div>
    );
  }

  if (datas === null) {
    return <Loading />;
  }

  const calculateAllPercentages = () => {
    // Filter only the people who have a checked score or potentialScore
    const checkedPeopleForScores = datas.filter(
      (data) => data.scores.first || data.scores.second || data.scores.third
    );

    const checkedPeopleForPotentialScores = datas.filter(
      (data) =>
        data.potentialScore.first ||
        data.potentialScore.second ||
        data.potentialScore.third
    );

    const totalPeopleForScores = checkedPeopleForScores.length;
    const totalPeopleForPotentialScores =
      checkedPeopleForPotentialScores.length;

    // Calculate percentages for scores
    const goodCountScores = checkedPeopleForScores.filter(
      (data) => data.scores.third
    ).length;
    const averageCountScores = checkedPeopleForScores.filter(
      (data) => data.scores.second
    ).length;
    const poorCountScores = checkedPeopleForScores.filter(
      (data) => data.scores.first
    ).length;

    const goodPercentageScores = isNaN(
      (goodCountScores / totalPeopleForScores) * 100
    )
      ? "0"
      : ((goodCountScores / totalPeopleForScores) * 100).toFixed(1);

    const averagePercentageScores = isNaN(
      (averageCountScores / totalPeopleForScores) * 100
    )
      ? "0"
      : ((averageCountScores / totalPeopleForScores) * 100).toFixed(1);

    const poorPercentageScores = isNaN(
      (poorCountScores / totalPeopleForScores) * 100
    )
      ? "0"
      : ((poorCountScores / totalPeopleForScores) * 100).toFixed(1);

    // Calculate percentages for potentialScore
    const goodCountPotential = checkedPeopleForPotentialScores.filter(
      (data) => data.potentialScore.third
    ).length;
    const averageCountPotential = checkedPeopleForPotentialScores.filter(
      (data) => data.potentialScore.second
    ).length;
    const poorCountPotential = checkedPeopleForPotentialScores.filter(
      (data) => data.potentialScore.first
    ).length;

    const goodPercentagePotential = isNaN(
      (goodCountPotential / totalPeopleForPotentialScores) * 100
    )
      ? "0"
      : ((goodCountPotential / totalPeopleForPotentialScores) * 100).toFixed(1);

    const averagePercentagePotential = isNaN(
      (averageCountPotential / totalPeopleForPotentialScores) * 100
    )
      ? "0"
      : ((averageCountPotential / totalPeopleForPotentialScores) * 100).toFixed(
          1
        );

    const poorPercentagePotential = isNaN(
      (poorCountPotential / totalPeopleForPotentialScores) * 100
    )
      ? "0"
      : ((poorCountPotential / totalPeopleForPotentialScores) * 100).toFixed(1);

    // Return both results
    return {
      scores: {
        goodPercentage: goodPercentageScores,
        averagePercentage: averagePercentageScores,
        poorPercentage: poorPercentageScores,
      },
      potentialScores: {
        goodPercentage: goodPercentagePotential,
        averagePercentage: averagePercentagePotential,
        poorPercentage: poorPercentagePotential,
      },
    };
  };
  const { scores, potentialScores } = calculateAllPercentages();

  return (
    <div className="pt-11 m-auto w-10/12">
      <p className="text-[#222] font-semibold">{t("analytic.title")}</p>
      <p className="text-[#777985] ps-8">{t("analytic.desc")}</p>
      <div className="flex items-start shadow-xl rounded-xl mt-4 gap-10 mb-10 relative bg-[#fff]">
        <div className="pt-6 pb-10 px-6 w-[60%]">
          {/* <div className="relative z-20">
            <div
              className={`flex items-center gap-2 px-2 py-0.5 rounded ${
                selectedDate !== null
                  ? "bg-[#F3F4F8] border border-[#ADADAD]"
                  : ""
              }`}
            >
              <button
                className="flex items-center border rounded-xl px-2 py-1"
                onClick={toggleCalendar}
              >
                <LuCalendarDays className="h-4 w-4 me-1" />
                {selectedDate ? (
                  <p className="text-[#222] text-xs">
                    {selectedDate instanceof Date
                      ? selectedDate.toLocaleDateString()
                      : selectedDate}
                  </p>
                ) : (
                  <p className="text-[#222] text-xs flex items-center gap-2">
                    {" "}
                    Бүх цаг үеийн <IoIosArrowDown className="h-3 w-3" />
                  </p>
                )}
              </button>
              {selectedDate !== null ? (
                <button
                  onClick={() => {
                    setSelectedDate(null);
                  }}
                  className="text-[#222] text-xs flex items-center gap-2"
                >
                  <IoIosClose className="h-5 w-5" />
                </button>
              ) : (
                ""
              )}
            </div>

            {showCalendar && (
              <div className="absolute bg-white border rounded mt-2 shadow-lg p-4">
                <div className="flex items-start gap-6">
                  <div className="border-r-2 border-[#EAEAEA] h-full w-[150px]">
                    <button
                      onClick={() => handlePresetDate("Өнөөдөр")}
                      className={`hover:bg-[#E5E7EB] px-2 rounded-xl mb-1 text-xs ${
                        selectedDate instanceof Date &&
                        selectedDate.toLocaleDateString() ===
                          new Date().toLocaleDateString()
                          ? "bg-[#324d72]"
                          : ""
                      }`}
                    >
                      Өнөөдөр
                    </button>
                    <button
                      onClick={() => handlePresetDate("Өнгөрсөн 7 хоног")}
                      className={`hover:bg-[#E5E7EB] px-2 rounded-xl mb-1 text-xs ${
                        selectedDate &&
                        selectedDate.toString() === "Өнгөрсөн 7 хоног"
                          ? "bg-[#324d72]"
                          : ""
                      }`}
                    >
                      Өнгөрсөн 7 хоног
                    </button>
                    <button
                      onClick={() => handlePresetDate("Өнгөрсөн сар")}
                      className={`hover:bg-[#E5E7EB] px-2 rounded-xl mb-1 text-xs ${
                        selectedDate &&
                        selectedDate.toString() === "Өнгөрсөн сар"
                          ? "bg-[#324d72]"
                          : ""
                      }`}
                    >
                      Өнгөрсөн сар
                    </button>
                    <button
                      onClick={() => handlePresetDate("Өнгөрсөн жил")}
                      className={`hover:bg-[#E5E7EB] px-2 rounded-xl mb-1 text-xs ${
                        selectedDate &&
                        selectedDate.toString() === "Өнгөрсөн жил"
                          ? "bg-[#324d72]"
                          : ""
                      }`}
                    >
                      Өнгөрсөн жил
                    </button>
                  </div>
                  <div>
                    <Calendar
                      selectRange={true}
                      className="border border-[#E5E7EB] rounded-xl"
                      onChange={handleDateChange}
                      value={selectedDate}
                    />
                    <div className="flex justify-end mt-4">
                      <button
                        className="bg-[#E5E7EB] px-5 py-1 rounded-lg mr-2 text-xs text-[#6B7280]"
                        onClick={toggleCalendar}
                      >
                        Болих
                      </button>
                      <button
                        onClick={() => {
                          setShowCalendar(false);
                        }}
                        className={`text-white px-5 py-1 rounded-lg text-xs ${
                          selectedDate !== null ? "bg-[#222]" : "bg-[#E5E7EB]"
                        }`}
                      >
                        Шүүх
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div> */}
          <div className="mt-4">
            <div className="flex">
              <div className="relative">
                <p className="rotate-[270deg] text-[#666874] text-[20px] absolute inset-y-1 left-48">
                  {t("analytic.potential")}
                </p>
                <img className="ms-4" src="/analytics/ver.png" alt="ver" />
              </div>
              <div className="ms-8 mt-8">
                <div className="flex gap-6">
                  <div
                    onMouseEnter={() => {
                      setHover(1);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(1);
                    }}
                    className={`w-[130px] h-[130px] p-2 bg-[#FFE070] text-center flex flex-col items-center justify-center rounded-bl-xl rounded-tr-xl ${
                      clicked === 0 ? "" : clicked === 1 ? "" : "opacity-60"
                    }`}
                  >
                    {hover === 1 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover1")}
                        </p>

                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.first && e.potentialScore.third
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex justify-center items-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) => e.scores.first && e.potentialScore.third
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box1")}
                        </p>
                        <p className="text-[#4b4B53] text-xs font-semibold text-center">
                          C1
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    onMouseEnter={() => {
                      setHover(2);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(2);
                    }}
                    className={`w-[130px] h-[130px] p-2 bg-[#7EEFB2] text-center flex flex-col items-center justify-center rounded-xl ${
                      clicked === 0 ? "" : clicked === 2 ? "" : "opacity-60"
                    }`}
                  >
                    {hover === 2 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover2")}
                        </p>

                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.second && e.potentialScore.third
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center justify-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) => e.scores.second && e.potentialScore.third
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box2")}
                          <p className="text-[#4b4B53] text-xs font-semibold text-center">
                            B1
                          </p>
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    onMouseEnter={() => {
                      setHover(3);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(3);
                    }}
                    className={`w-[130px] h-[130px] p-2 bg-[#00D97D] text-center flex flex-col items-center justify-center rounded-br-xl rounded-tl-xl relative ${
                      clicked === 0 ? "" : clicked === 3 ? "" : "opacity-60"
                    }`}
                  >
                    <img
                      className="absolute bottom-0 left-0 opacity-30"
                      src="/analytics/green.svg"
                      alt="ver"
                    />
                    {hover === 3 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover3")}
                        </p>
                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.third && e.potentialScore.third
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>{" "}
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center justify-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) => e.scores.third && e.potentialScore.third
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box3")}
                          <p className="text-[#4b4B53] text-xs font-semibold text-center">
                            A1
                          </p>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-6 my-6">
                  <div
                    onMouseEnter={() => {
                      setHover(4);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(4);
                    }}
                    className={`w-[130px] h-[130px] p-2 relative z-10 bg-[#FFBD70] text-center flex flex-col items-center justify-center rounded-xl ${
                      clicked === 0 ? "" : clicked === 4 ? "" : "opacity-60"
                    }`}
                  >
                    {hover === 4 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover4")}
                        </p>
                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.first && e.potentialScore.second
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>{" "}
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center justify-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) => e.scores.first && e.potentialScore.second
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box4")}
                          <p className="text-[#4b4B53] text-xs font-semibold text-center">
                            C2
                          </p>
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    onMouseEnter={() => {
                      setHover(5);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(5);
                    }}
                    className={`w-[130px] h-[130px] p-2 relative z-10 bg-[#FFE070] text-center flex flex-col items-center justify-center rounded-xl ${
                      clicked === 0 ? "" : clicked === 5 ? "" : "opacity-60"
                    }`}
                  >
                    {hover === 5 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover5")}
                        </p>

                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.second && e.potentialScore.second
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center justify-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) =>
                                  e.scores.second && e.potentialScore.second
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box5")}
                        </p>
                        <p className="text-[#4b4B53] text-xs font-semibold text-center">
                          B2
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    onMouseEnter={() => {
                      setHover(6);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(6);
                    }}
                    className={`w-[130px] h-[130px] p-2 relative z-10 bg-[#7EEFB2] text-center flex flex-col items-center justify-center rounded-xl ${
                      clicked === 0 ? "" : clicked === 6 ? "" : "opacity-60"
                    }`}
                  >
                    {hover === 6 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover6")}
                        </p>

                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.third && e.potentialScore.second
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center justify-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) => e.scores.third && e.potentialScore.second
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box6")}
                          <p className="text-[#4b4B53] text-xs font-semibold text-center">
                            A2
                          </p>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-6">
                  <div
                    onMouseEnter={() => {
                      setHover(7);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(7);
                    }}
                    className={`w-[130px] h-[130px] p-2 bg-[#FF5F6E] text-center flex flex-col items-center justify-center rounded-br-xl rounded-tl-xl relative ${
                      clicked === 0 ? "" : clicked === 7 ? "" : "opacity-60"
                    }`}
                  >
                    <img
                      className="absolute bottom-0 left-0 opacity-30"
                      src="/analytics/red.svg"
                      alt="ver"
                    />

                    {hover === 7 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover7")}
                        </p>

                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.first && e.potentialScore.first
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center justify-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) => e.scores.first && e.potentialScore.first
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box7")}
                        </p>
                        <p className="text-[#4b4B53] text-xs font-semibold text-center">
                          C3
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    onMouseEnter={() => {
                      setHover(8);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(8);
                    }}
                    className={`w-[130px] h-[130px] p-2 bg-[#FFBD70] text-center flex flex-col items-center justify-center rounded-xl ${
                      clicked === 0 ? "" : clicked === 8 ? "" : "opacity-60"
                    }`}
                  >
                    {hover === 8 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover8")}
                        </p>

                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.second && e.potentialScore.first
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center justify-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) => e.scores.second && e.potentialScore.first
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box8")}
                        </p>
                        <p className="text-[#4b4B53] text-xs font-semibold text-center">
                          B3
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    onMouseEnter={() => {
                      setHover(9);
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    onClick={() => {
                      setClicked(9);
                    }}
                    className={`w-[130px] h-[130px] p-2 bg-[#FFE070] text-center flex flex-col items-center justify-center rounded-bl-xl rounded-tr-xl ${
                      clicked === 0 ? "" : clicked === 9 ? "" : "opacity-60"
                    }`}
                  >
                    {hover === 9 ? (
                      <div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.boxHover9")}
                        </p>

                        <p className="text-lg font-semibold text-[#4B4B53]">
                          {
                            datas.filter(
                              (e) => e.scores.third && e.potentialScore.first
                            ).length
                          }
                        </p>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.worker")}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center justify-center gap-1 mb-2">
                          <MdOutlineGroup className="text-[#4B4B53] text-2xl" />
                          <p className="text-lg font-semibold text-[#4B4B53]">
                            {
                              datas.filter(
                                (e) => e.scores.third && e.potentialScore.first
                              ).length
                            }
                          </p>
                        </div>
                        <p className="text-[#4B4B53] text-sm font-semibold">
                          {t("analytic.box9")}
                        </p>
                        <p className="text-[#4b4B53] text-xs font-semibold text-center">
                          A3
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <img className="ms-8 mt-4" src="/analytics/hor.png" alt="hor" />
            <div className="flex flex-col items-center justify-center">
              <p className="text-[#666874] text-[20px]">
                {t("analytic.performance")}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[40%]">
          <div className="p-4 mb-10">
            <h2 className="text-lg font-bold mb-4">
              {t("analytic.barChartPer")}
            </h2>
            <div className="flex justify-center items-end h-40 mb-4 gap-4">
              <div className="flex flex-col items-center">
                <div className="flex items-end">
                  {generateColumns(barData.first, "bg-[#FF5F6E]", true)}
                </div>
                <span className="text-sm text-[#324D72]">
                  {scores?.poorPercentage}%
                </span>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex items-end">
                  {generateColumns(barData.second, "bg-[#FFE070]", false)}
                </div>
                <span className="text-sm text-[#324D72]">
                  {scores?.averagePercentage}%
                </span>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex items-end">
                  {generateColumns(barData.third, "bg-[#00D97D]", false)}
                </div>
                <span className="text-sm text-[#324D72]">
                  {scores?.goodPercentage}%
                </span>
              </div>
            </div>

            <div className="flex justify-center space-x-8">
              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#FF5F6E] rounded-full mr-2"></div>
                <span className="text-[10px]">{t("analytic.bad")}</span>
              </div>
              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#FFE070] rounded-full mr-2"></div>
                <span className="text-[10px]">{t("analytic.avg")}</span>
              </div>

              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#00D97D] rounded-full mr-2"></div>
                <span className="text-[10px]">{t("analytic.good")}</span>
              </div>
            </div>
          </div>
          <div className="p-4">
            <h2 className="text-lg font-bold mb-4">
              {t("analytic.barChartPot")}
            </h2>
            <div className="flex justify-center items-end h-40 mb-4 gap-4">
              <div className="flex flex-col items-center">
                <div className="flex items-end">
                  {generatePotentialColumns(
                    potentialBarData.first,
                    "bg-[#FF5F6E]",
                    true
                  )}
                </div>
                <span className="text-sm text-[#324D72]">
                  {potentialScores?.poorPercentage}%
                </span>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex items-end">
                  {generatePotentialColumns(
                    potentialBarData.second,
                    "bg-[#FFE070]",
                    false
                  )}
                </div>
                <span className="text-sm text-[#324D72]">
                  {potentialScores?.averagePercentage}%
                </span>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex items-end">
                  {generatePotentialColumns(
                    potentialBarData.third,
                    "bg-[#00D97D]",
                    false
                  )}
                </div>
                <span className="text-sm text-[#324D72]">
                  {potentialScores?.goodPercentage}%
                </span>
              </div>
            </div>

            <div className="flex justify-center space-x-8">
              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#FF5F6E] rounded-full mr-2"></div>
                <span className="text-[10px]">{t("analytic.bad")}</span>
              </div>
              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#FFE070] rounded-full mr-2"></div>
                <span className="text-[10px]">{t("analytic.avg")}</span>
              </div>

              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#00D97D] rounded-full mr-2"></div>
                <span className="text-[10px]">{t("analytic.good")}</span>
              </div>
            </div>
          </div>
        </div>
        {clicked === 0 ? (
          ""
        ) : (
          <div className="absolute top-4 -right-[24px] w-[35%] shadow-2xl rounded-xl h-[550px] mt-15 me-10 z-10 bg-white">
            <div className="relative">
              <button
                onClick={() => {
                  setClicked(0);
                }}
                className="text-xl absolute top-4 right-4"
              >
                <IoMdClose className="text-white" />
              </button>
              {renderTitle()}
              <div className=" px-6 py-4 h-[75%] overflow-y-scroll">
                {renderContent()}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <p className="text-[#222] font-semibold mb-10">
          {t("analytic.title2")}
        </p>
        <div className="flex items-start gap-10 mb-10">
          <div className="shadow-xl w-full">
            <div className="overflow-x-auto h-[400px]">
              <div className="overflow-y-scroll max-h-[400px]">
                <table className="min-w-full bg-white">
                  <thead className="sticky top-0 bg-white z-20">
                    <tr>
                      <th className="py-2 border-b">
                        {t("analytic.fullname")}
                      </th>
                      <th className="py-2 border-b">
                        {t("analytic.department")}
                      </th>
                      <th className="py-2 border-b">
                        {t("analytic.performScore")}
                      </th>
                      <th className="py-2 border-b">
                        {t("analytic.potentialScore")}
                      </th>
                      <th className="py-2 border-b">
                        {" "}
                        {t("analytic.comment")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas.map((row, index) => (
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-[#F5F5F6]" : "bg-white"
                        }`}
                        key={index}
                      >
                        <td className="py-2 border-b ps-4">
                          {row.lastName}, {row.firstName}
                        </td>
                        <td className="py-2 border-b text-center">
                          {row.department}
                        </td>
                        <td className="py-2 border-b">
                          <div className="flex justify-center gap-4">
                            <div className="relative group">
                              <button
                                onClick={() =>
                                  handleButtonClick(index, "first")
                                }
                                className={`px-2 text-sm ${
                                  row.scores.first
                                    ? "border rounded-full border-[#324D72] text-[#324D72]"
                                    : "text-[#777985]"
                                }`}
                              >
                                1
                              </button>
                              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden w-max px-3 py-1 text-sm text-white bg-gray-700 rounded group-hover:block z-10">
                                {t("analytic.performHover1")}
                              </div>
                            </div>

                            <div className="relative group">
                              <button
                                onClick={() =>
                                  handleButtonClick(index, "second")
                                }
                                className={`px-2 text-sm ${
                                  row.scores.second
                                    ? "border rounded-full border-[#324D72] text-[#324D72]"
                                    : "text-[#777985]"
                                }`}
                              >
                                2
                              </button>
                              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden w-max px-3 py-1 text-sm text-white bg-gray-700 rounded group-hover:block z-10">
                                {t("analytic.performHover2")}
                              </div>
                            </div>

                            <div className="relative group">
                              <button
                                onClick={() =>
                                  handleButtonClick(index, "third")
                                }
                                className={`px-2 text-sm ${
                                  row.scores.third
                                    ? "border rounded-full border-[#324D72] text-[#324D72]"
                                    : "text-[#777985]"
                                }`}
                              >
                                3
                              </button>
                              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden w-max px-3 py-1 text-sm text-white bg-gray-700 rounded group-hover:block z-10">
                                {t("analytic.performHover3")}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="py-2 border-b">
                          <div className="flex justify-center gap-4">
                            <div className="relative group">
                              <button
                                onClick={() =>
                                  handlePotentialButtonClick(index, "first")
                                }
                                className={`px-2 text-sm ${
                                  row.potentialScore.first
                                    ? "border rounded-full border-[#324D72] text-[#324D72]"
                                    : "text-[#777985]"
                                }`}
                              >
                                1
                              </button>
                              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden w-max px-3 py-1 text-sm text-white bg-gray-700 rounded group-hover:block z-10">
                                {t("analytic.potentialHover1")}
                              </div>
                            </div>

                            <div className="relative group">
                              <button
                                onClick={() =>
                                  handlePotentialButtonClick(index, "second")
                                }
                                className={`px-2 text-sm ${
                                  row.potentialScore.second
                                    ? "border rounded-full border-[#324D72] text-[#324D72]"
                                    : "text-[#777985]"
                                }`}
                              >
                                2
                              </button>
                              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden w-max px-3 py-1 text-sm text-white bg-gray-700 rounded group-hover:block z-10">
                                {t("analytic.potentialHover2")}
                              </div>
                            </div>
                            <div className="relative group">
                              <button
                                onClick={() =>
                                  handlePotentialButtonClick(index, "third")
                                }
                                className={`px-2 text-sm ${
                                  row.potentialScore.third
                                    ? "border rounded-full border-[#324D72] text-[#324D72]"
                                    : "text-[#777985]"
                                }`}
                              >
                                3
                              </button>
                              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden w-max px-3 py-1 text-sm text-white bg-gray-700 rounded group-hover:block z-10">
                                {t("analytic.potentialHover3")}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <input
                            className={`border-none p-0 bg-none w-full py-1  ${
                              index % 2 === 0 ? "bg-[#F5F5F6]" : "bg-white"
                            }`}
                            type="text"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analystic;
